import axios from 'axios'

export const bytesToHumanReadableSize = bytes => {
  if (bytes) {
    const kB = parseFloat((bytes / 1000).toFixed(2))
    return kB >= 1000 ? `${(kB / 1000).toFixed(2)} MB` : `${kB} KB`
  }
  return null
}

export const downloadFileByRequest = (url, fileName) => {
  axios({url: url, method: 'GET', responseType: 'blob'})
    .then(({ data }) => saveFileFromResponse(data, fileName))
    .catch(e => console.log('download file error: ', e))
}

export const downloadFileByUrl = (url, fileName) => {
  const fileLink = document.createElement('a')
  fileLink.href = url
  fileLink.target = '_blank'
  fileLink.download = fileName
  document.body.appendChild(fileLink)
  fileLink.click()
}

export const saveFileFromResponse = (data, fileName) => downloadFileFromDOMElement(
  window.URL.createObjectURL(data), fileName
)

export const saveExcelFileFromResponse = (data, fileName) => {
  if (!window.navigator.msSaveOrOpenBlob) {
    // BLOB NAVIGATOR
    downloadFileFromDOMElement(window.URL.createObjectURL(new Blob([data])), fileName)
  } else {
    // BLOB FOR EXPLORER 11
    window.navigator.msSaveOrOpenBlob(new Blob([data]), fileName)
  }
}

const downloadFileFromDOMElement = (objectUrl, fileName) => {
  const element = document.createElement('a')
  element.href =  objectUrl
  element.download = fileName
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
  window.URL.revokeObjectURL(objectUrl)
}

export const downloadFileFromISO_8859_1 = (data, fileName) => {
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(
    new Blob(formatISO_8859_1ToBytesArray(data), { type: 'text/plain; charset=ISO-8859-1' })
  )
  link.setAttribute('download', `${fileName}.mp3`)
  document.body.appendChild(link)
  link.click()
}

export const formatISO_8859_1ToBytesArray = text => {
  const length = text.length
  const result = new Uint8Array(length)
  for (let i = 0; i < length; i++) {
    const code = text.charCodeAt(i)
    result[i] = code > 255 ? 32 : code
  }
  return [result.buffer]
}
