export default {
  // Wage by minute
  wageByMinute: [],
  // Additional wage
  additionalWage: [],
  // Service tariff history
  serviceTariffHistory: [],
  serviceTariffHistoryCount: 0,
  // Service and wage calculation
  exportProcessActive: false
}
