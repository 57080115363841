export default {
  getNotifications: state => state.notifications,
  getNotificationsTotalCount: state => state.notificationsTotalCount,
  getNotificationType: state => state.notificationType,
  getNotificationStatus: state => state.notificationStatus,
  getNotificationMethods: state => state.notificationMethods,
  getSystemNotificationConditionCategory: state => state.systemNotificationConditionCategory,
  getNotificationUsersPerPage: state => state.notificationUsersPerPage,
  getNotificationUsersPerPageCount: state => state.notificationUsersPerPageCount,
  getSystemNotificationPatientFormTypes: state => state.systemNotificationPatientFormTypes,
  onlineSystemNotifications: state => state.onlineSystemNotifications
}
