import state from './moduleServiceCalculationState'
import mutations from './moduleServiceCalculationMutations'
import actions from './moduleServiceCalculationActions'
import getters from './moduleServiceCalculationGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
