import { isNotEnoughMaterialByLot } from '@/composables/materialHelper'
import { mixins } from '@/mixins'
import { addDataToLocalStorageByPrefix, getDataFromLocalStorageByPrefix } from '@/composables/localStorageHelper'
import { showWarningOperationEternalToast } from '@/composables/toast'

export const serviceHasNotEnoughMaterial = (service, count = 1) => {
  let has = false
  count = count ? parseFloat(count) : null
  if (
    count && mixins.methods.isProhibitZeroMaterialConsumption() && service.consumption_rate
    && service.consumption_rate.length
  ) {
    service.consumption_rate.forEach(rate => {
      const lot = rate.material_lots && rate.material_lots.length ? rate.material_lots[0] : null
      if (!has && isNotEnoughMaterialByLot(rate.part_rate * count, lot)) {
        has = true
      }
    })
  }
  return has
}

export const sortServicesByPositions = (services, localStorageKey) => {
  let servicesList = []
  if (services.length) {
    const positions = getDataFromLocalStorageByPrefix(localStorageKey)
    const excludedIds = []
    if (positions) {
      Object.keys(positions).map(k => {
        if (!services.find(s => s.id.toString() === k)) {
          excludedIds.push(k)
        }
      })
    }
    const result = []
    const newServices = []
    services.map(s => {
      if (positions && s.id.toString() in positions) {
        const oldPosition = positions[s.id]
        let counter = 0
        if (excludedIds) {
          counter = excludedIds.reduce((v, i) =>  oldPosition > positions[i] ? v + 1 : v, 0)
        }
        result[oldPosition - counter] = s
      } else {
        newServices.push(s)
      }
    })
    servicesList = result.concat(newServices)

    const data = {}
    servicesList.map((s, i) => data[s.id] = i)
    addDataToLocalStorageByPrefix(localStorageKey, data)
  } else {
    servicesList = []
  }
  return servicesList
}

export const notEnoughServicesMaterials = (materialId, lotId, services, showMessage = false) => {
  const info = {available: 0, need: 0, name: null}
  let notEnough = false
  services.map(s => {
    if (s.materials) {
      s.materials.map(m => {
        if (m.lot_id && m.lot_info && m.id === materialId && m.lot_id === lotId) {
          let materialExpendedNumber = m.lot_info.material_expended_number
            ? m.lot_info.material_expended_number : 0
          if (m.from_history && materialExpendedNumber) {
            materialExpendedNumber -= m.total_material_expended_number
          }
          const numberOfMaterial = m.lot_info.number_of_material ? m.lot_info.number_of_material : 0
          let diff = numberOfMaterial - materialExpendedNumber
          info.available = diff < 0 ? 0 : diff
          info.need += s.count * parseFloat(m.system_column_count ? m.system_column_count : m.part_rate)
          info.name = m.material_name
        }
      })
    }
  })
  const diff = info.available - info.need
  if (diff < 0) {
    if (showMessage) {
      const message = `Материал "${info.name}" доступен в количестве ${info.available}. 
        Вы запрашиваете ${info.need}. Уберите из услуги недостоющий материал, обнулите его количество, либо пополните
        его на складе.`
      showWarningOperationEternalToast(message, 'Недостаточно материала!')
    }
    notEnough = true
  }
  return notEnough
}
