import { getTelegramIcon, getViberIcon } from '@/composables/chat'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ToastificationOnlineAppointment from '@/views/components/toasts/ToastificationOnlineAppointment.vue'
import ToastificationCustom from '@/views/components/toasts/ToastificationCustom.vue'
import ToastificationNewLead from '@/views/components/toasts/ToastificationNewLead.vue'
import ToastificationPatientProcessedAppointmentNotification
    from '@/views/components/toasts/ToastificationPatientProcessedAppointmentNotification.vue'

export const showCommonToast = (
  title, text, variant, listeners = {}, options = {}, icon = 'BellIcon'
) => showToast(ToastificationContent, {title, text, variant, icon}, listeners, options)

export const showInfoOperationEternalToast = (text, title = 'Внимание!') => showToast(
  ToastificationContent,
  {text, title, variant: 'info', icon: 'InfoIcon'},
  {},
  {closeOnClick: true, timeout: false}
)

export const showInfoOperationToast = (text, title = 'Внимание!') => showToast(
  ToastificationContent,
  {text, title, variant: 'info', icon: 'InfoIcon'},
  {},
  {}
)

export const showFailedOperationEternalToast = (text, title = 'Операция не выполнена') => showToast(
  ToastificationContent,
  {text, title, variant: 'danger', icon: 'InfoIcon'},
  {},
  {closeOnClick: true, timeout: false}
)

export const showFailedOperationToast = (
  text, title = 'Операция не выполнена', icon = 'InfoIcon'
) => showToast(
  ToastificationContent,
  {text, title, variant: 'danger', icon: icon},
  {},
  {timeout: 4000}
)

export const showSuccessOperationEternalToast = (
  text, title = 'Операция выполнена', icon = 'BellIcon'
) => showToast(
  ToastificationContent,
  {text, title, variant: 'success', icon: icon},
  {},
  {closeOnClick: true, timeout: false}
)

export const showSuccessOperationToast = (
  text, title = 'Операция выполнена', icon = 'BellIcon'
) => showToast(
  ToastificationContent,
  {text, title, variant: 'success', icon: icon},
  {},
  {timeout: 4000}
)

export const showWarningOperationToast = (
  text, title = 'Внимание!', icon = 'BellIcon', options = {}
) => showToast(
  ToastificationContent,
  {text, title, variant: 'warning', icon: icon},
  {},
  {timeout: 4000, ...options}
)

export const showWarningOperationEternalToast = (
  text, title = 'Внимание!', icon = 'BellIcon'
) => showToast(
  ToastificationContent,
  {text, title, variant: 'warning', icon: icon},
  {},
  {closeOnClick: true, timeout: false}
)

export const showOnlineAppointmentToast = data => showToast(
  ToastificationOnlineAppointment,
  {
    title: data.title,
    serviceName: data.service_name,
    patientId: data.patient_id,
    doctorFullName: data.doctor_full_name,
    patientFullName: data.patient_full_name,
    patientPhoneCode: data.patient_phone_code,
    patientPhoneNumber: data.patient_phone_number,
    time: data.time,
    variant: 'info',
    icon: 'GlobeIcon'
  },
  {},
  {closeOnClick: true, timeout: false}
)

export const showNewMessageToast = (title, text) => showToast(
  ToastificationContent,
  {title, text, variant: 'info', icon: 'MessageSquareIcon'},
  {},
  {timeout: false}
)

export const showTelegramToast = (title, text) => showToast(
  ToastificationContent,
  {title, text, variant: 'info', customIconSrc: getTelegramIcon()},
  {},
  {timeout: false}
)

export const showViberToast = (title, text) => showToast(
  ToastificationContent,
  {title, text, variant: 'primary', customIconSrc: getViberIcon()},
  {},
  {timeout: false}
)

export const showCenteredToast = (
  type, title, text, files = [], listeners = {}, options = {}
) => showToast(
  ToastificationCustom,
  {
    title: title,
    text: text,
    files: files
  },
  listeners,
  options
)

export const showNewLeadToast = (
  id, date, source, patientFullName, phoneCode, phone, time, chatId, listeners = {}, options = {}
) => showToast(
  ToastificationNewLead,
  {id, date, source, patientFullName, phoneCode, phone, time, chatId},
  listeners,
  options
)

export const showPatientProcessedAppointmentToast = (
  appointmentId, title, patientId, patientFullName, phoneCode, phone, note, rate, appointmentDate, action,
  listeners = {}, options = {}
) => showToast(
  ToastificationPatientProcessedAppointmentNotification,
  {appointmentId, title, patientId, patientFullName, phoneCode, phone, note, rate, appointmentDate, action},
  listeners,
  options
)

export const showToast = (component, props = {}, listeners = {}, options = {}) => {
  Vue.$toast({component, props, listeners}, options)
}