import {entityLead} from '@/libs/acl/entities'

export default [
  {
    path: '/apps/leads',
    name: 'apps-leads',
    component: () => import('@/views/lead/Lead.vue'),
    meta: {
      contentClass: 'leads-application',
      resource: entityLead,
      action: 'read',
      title: 'Leads'
    },
  },
  {
    path: '/apps/leads/profile/:leadId',
    name: 'app-lead-profile',
    component: () => import('@/views/lead/LeadProfile.vue'),
    meta: {
      breadcrumb: [
        { title: 'Главная', url: '/' },
        { title: 'Список лидов', url: '/apps/leads' },
      ],
      pageTitle: 'Карточка лида',
      resource: entityLead,
      action: 'read',
      title: 'Lead profile'
    }
  },
]
