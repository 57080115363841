export default {
  viewType: Object.freeze({
    DAY_GRID_MONTH: 'dayGridMonth',
    TIME_GRID_WEEK: 'timeGridWeek',
    LIST_DAY_EXTENDED: 'listDayExtended',
    AGENDA: 'agenda',
    FREE_TIME: 'freeTime',
    RESERVE: 'reserve',
    AWAIT_STATUS: 'awaitStatus',
    ONLINE_STATUS: 'onlineStatus',
    CHANGE_STATUS: 'changeStatus',
    LOADING_DOCTORS: 'loadingDoctors',
  }),
}
