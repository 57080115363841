import { mixins } from '@/mixins'

export const isNotEnoughMaterialByLot = (partRate, lot) => {
  if (mixins.methods.isProhibitZeroMaterialConsumption() && lot) {
    partRate = partRate ? parseFloat(partRate) : 0
    const initNumberOfMaterial = lot.number_of_material ? lot.number_of_material : 0
    const materialExpendedNumber = lot.material_expended_number ? lot.material_expended_number : 0
    return (initNumberOfMaterial - materialExpendedNumber - partRate) < 0
  }
  return false
}

export const isNotEnoughMaterial = (partRate, numberOfMaterial, materialExpendedNumber) => {
  if (mixins.methods.isProhibitZeroMaterialConsumption()) {
    partRate = partRate ? parseFloat(partRate) : 0
    numberOfMaterial = numberOfMaterial ? numberOfMaterial : 0
    materialExpendedNumber = materialExpendedNumber ? materialExpendedNumber : 0
    return (numberOfMaterial - materialExpendedNumber - partRate) < 0
  }
  return false
}
