import Vue from 'vue';

export default {
  // Purchase order
  SET_PURCHASE_ORDERS_PER_PAGE: (state, items) => state.purchaseOrders = items,
  SET_PURCHASE_ORDERS_PER_PAGE_COUNT: (state, count) => state.purchaseOrdersTotalCount = count,
  REMOVE_PURCHASE_ORDER(state, itemId) {
    const index = state.purchaseOrders.findIndex(us => us.id === itemId)
    state.purchaseOrders.splice(index, 1)
    state.purchaseOrdersTotalCount -= 1
  },
  CHANGE_PURCHASE_ORDER_STATUS(state, data) {
    const index = state.purchaseOrders.findIndex(us => us.id === data.id)
    state.purchaseOrders[index].status = data.status
  },
  // Implant system
  SET_IMPLANT_SYSTEM_PER_PAGE: (state, items) => state.implantSystemsPerPage = items,
  SET_IMPLANT_SYSTEM_PER_PAGE_COUNT: (state, count) => state.implantSystemsPerPageCount = count,
  SET_DENTAL_LAB_IMPLANT_SYSTEMS: (state, items) => state.dentalLabImplantSystems = items,
  ADD_IMPLANT_SYSTEM(state, item) {
    state.implantSystemsPerPage.push(item)
    state.implantSystemsPerPageCount += 1
  },
  EDIT_IMPLANT_SYSTEM(state, item) {
    const index = state.implantSystemsPerPage.findIndex(us => us.id === item.id)
    Vue.set(state.implantSystemsPerPage, index, item)
  },
  REMOVE_IMPLANT_SYSTEM(state, itemId) {
    const index = state.implantSystemsPerPage.findIndex(us => us.id === itemId)
    state.implantSystemsPerPage.splice(index, 1)
    state.implantSystemsPerPageCount -= 1
  },
  // Implant work type
  SET_IMPLANT_WORK_TYPE_PER_PAGE: (state, items) => state.implantWorkTypesPerPage = items,
  SET_IMPLANT_WORK_TYPE_PER_PAGE_COUNT: (state, count) => state.implantWorkTypesPerPageCount = count,
  SET_DENTAL_LAB_IMPLANT_WORK_TYPES: (state, items) => state.dentalLabImplantWorkTypes = items,
  ADD_IMPLANT_WORK_TYPE(state, item) {
    state.implantWorkTypesPerPage.push(item)
    state.implantWorkTypesPerPageCount += 1
  },
  EDIT_IMPLANT_WORK_TYPE(state, item) {
    const index = state.implantWorkTypesPerPage.findIndex(us => us.id === item.id)
    Vue.set(state.implantWorkTypesPerPage, index, item)
  },
  REMOVE_IMPLANT_WORK_TYPE(state, itemId) {
    const index = state.implantWorkTypesPerPage.findIndex(us => us.id === itemId)
    state.implantWorkTypesPerPage.splice(index, 1)
    state.implantWorkTypesPerPageCount -= 1
  },
  // Material type
  SET_MATERIAL_TYPE_PER_PAGE: (state, items) => state.materialTypesPerPage = items,
  SET_MATERIAL_TYPE_PER_PAGE_COUNT: (state, count) => state.materialTypesPerPageCount = count,
  SET_DENTAL_LAB_MATERIAL_TYPES: (state, items) => state.dentalLabMaterialTypes = items,
  ADD_MATERIAL_TYPE(state, item) {
    state.materialTypesPerPage.push(item)
    state.materialTypesPerPageCount += 1
  },
  EDIT_MATERIAL_TYPE(state, item) {
    const index = state.materialTypesPerPage.findIndex(us => us.id === item.id)
    Vue.set(state.materialTypesPerPage, index, item)
  },
  REMOVE_MATERIAL_TYPE(state, itemId) {
    const index = state.materialTypesPerPage.findIndex(us => us.id === itemId)
    state.materialTypesPerPage.splice(index, 1)
    state.materialTypesPerPageCount -= 1
  },
  // Work type
  SET_WORK_TYPE_PER_PAGE: (state, items) => state.workTypesPerPage = items,
  SET_WORK_TYPE_PER_PAGE_COUNT: (state, count) => state.workTypesPerPageCount = count,
  SET_DENTAL_LAB_WORK_TYPES: (state, items) => state.dentalLabWorkTypes = items,
  ADD_WORK_TYPE(state, item) {
    state.workTypesPerPage.push(item)
    state.workTypesPerPageCount += 1
  },
  EDIT_WORK_TYPE(state, item) {
    const index = state.workTypesPerPage.findIndex(us => us.id === item.id)
    Vue.set(state.workTypesPerPage, index, item)
  },
  REMOVE_WORK_TYPE(state, itemId) {
    const index = state.workTypesPerPage.findIndex(us => us.id === itemId)
    state.workTypesPerPage.splice(index, 1)
    state.workTypesPerPageCount -= 1
  },
  // Color palette
  SET_COLOR_PALETTE_PER_PAGE: (state, items) => state.colorPalettePerPage = items,
  SET_COLOR_PALETTE_PER_PAGE_COUNT: (state, count) => state.colorPalettePerPageCount = count,
  SET_DENTAL_LAB_COLOR_PALETTE: (state, items) => state.dentalLabColorPalette = items,
  ADD_COLOR_PALETTE(state, item) {
    state.colorPalettePerPage.push(item)
    state.colorPalettePerPageCount += 1
  },
  EDIT_COLOR_PALETTE(state, item) {
    const index = state.colorPalettePerPage.findIndex(us => us.id === item.id)
    Vue.set(state.colorPalettePerPage, index, item)
  },
  REMOVE_COLOR_PALETTE(state, itemId) {
    const index = state.colorPalettePerPage.findIndex(us => us.id === itemId)
    state.colorPalettePerPage.splice(index, 1)
    state.colorPalettePerPageCount -= 1
  },
}
