const mouseoverClass = {
  bind(el, binding) {
    const { value = {} } = binding
    if (value.overClass) {
      el.addEventListener('mouseenter',() => {
        el.classList.add(value.overClass)
        if (value.outClass) {
          el.classList.remove(value.outClass)
        }
      })
      el.addEventListener('mouseleave',() => {
        if (value.outClass) {
          el.classList.add(value.outClass)
        }
        el.classList.remove(value.overClass)
      })
    }
  },
  unbind(el) {
    el.removeEventListener('mouseenter', mouseoverClass.bind)
    el.removeEventListener('mouseleave', mouseoverClass.bind)
  }
}

export { mouseoverClass }
