export default {
  // Analytics -> Revenue
  getAnalyticsRevenueTotalByDirections: state => state.analyticsRevenueTotalByDirections,
  // Analytics -> Patient appointment schedule
  getAnalyticsPatientAppointmentScheduleTotalByHowDidKnow:
    state => state.analyticsPatientAppointmentScheduleTotalByHowDidKnow,
  getAnalyticsPatientAppointmentScheduleTotalByHowToContact:
    state => state.analyticsPatientAppointmentScheduleTotalByHowToContact,
  getAnalyticsPatientAppointmentScheduleTotalByStatuses:
    state => state.analyticsPatientAppointmentScheduleTotalByStatuses,
  analyticsPatientAppointmentScheduleTotalByReviews: state => state.analyticsPatientAppointmentScheduleTotalByReviews,
  // Analytics -> Patients
  getAnalyticsPatientsTotalByDentalServices:
    state => state.analyticsPatientsTotalByDentalServices,
  getAnalyticsPatientsTotalPatientsCount: state => state.analyticsPatientsTotalPatientsCount,
  // Analytics -> Leads
  getAnalyticsLeadsInfo: state => state.analyticsLeadsInfo
}
