import axios from '@/libs/axios'

export default {
  // Document settings
  addDocumentSettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `document-settings/`,
        method: 'POST',
        data: params
      })
        .then(res => {
          commit('ADD_DOCUMENT_SETTINGS', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  getDocumentSettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `document-settings/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_DOCUMENT_SETTINGS', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  editDocumentSettings({commit}, item) {
    return new Promise((resolve, reject) => {
      axios({
        url: `document-settings/${item.id}/`,
        method: 'PATCH',
        data: item
      })
        .then(res => {
          commit('EDIT_DOCUMENT_SETTINGS', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  // Patient reception schedule settings
  addPatientReceptionScheduleSettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient-reception-schedule-settings/`,
        method: 'POST',
        data: params
      })
        .then(res => {
          commit('ADD_PATIENT_RECEPTION_SCHEDULE_SETTINGS', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  getPatientReceptionScheduleSettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient-reception-schedule-settings/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_PATIENT_RECEPTION_SCHEDULE_SETTINGS', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  editPatientReceptionScheduleSettings({commit}, item) {
    return new Promise((resolve, reject) => {
      axios({url: `patient-reception-schedule-settings/${item.id}/`, method: 'PATCH', data: item})
        .then(res => {
          commit('EDIT_PATIENT_RECEPTION_SCHEDULE_SETTINGS', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  // Cash register settings
  addCashRegisterSettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `cash-register-settings/`,
        method: 'POST',
        data: params
      })
        .then(res => {
          commit('ADD_CASH_REGISTER_SETTINGS', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  getCashRegisterSettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `cash-register-settings/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_CASH_REGISTER_SETTINGS', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  editCashRegisterSettings({commit}, item) {
    return new Promise((resolve, reject) => {
      axios({
        url: `cash-register-settings/${item.id}/`,
        method: 'PATCH',
        data: item
      })
        .then(res => {
          commit('EDIT_CASH_REGISTER_SETTINGS', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  // Integrator system modules settings
  fetchIntegratorSystemModulesSettings({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `integrator/${id}/system-module-setting`,
        method: 'GET'
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  saveIntegratorSystemModulesSettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `integrator-system-module-setting/`,
        method: 'POST',
        data: params
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  // Auth group system modules settings
  fetchAuthGroupSystemModulesSettings({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `auth-group/${id}/system-module-setting`,
        method: 'GET'
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  // Price settings
  addPriceSettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `price-settings/`,
        method: 'POST',
        data: params
      })
        .then(res => {
          commit('ADD_PRICE_SETTINGS', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  getPriceSettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `price-settings/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_PRICE_SETTINGS', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  editPriceSettings({commit}, item) {
    return new Promise((resolve, reject) => {
      axios({
        url: `price-settings/${item.id}/`,
        method: 'PATCH',
        data: item
      })
        .then(res => {
          commit('EDIT_PRICE_SETTINGS', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  priceSettingsRecalculateNonRegulatedServices({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `price-settings/recalculate-non-regulated-services`,
        method: 'POST',
        data: params
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  priceSettingsRecalculateRegulatedServices({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `price-settings/recalculate-regulated-services`,
        method: 'POST',
        data: params
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  // Treatment diary settings
  addTreatmentDiarySettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `treatment-diary-settings/`,
        method: 'POST',
        data: params
      })
        .then(res => {
          commit('ADD_TREATMENT_DIARY_SETTINGS', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  getTreatmentDiarySettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `treatment-diary-settings/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_TREATMENT_DIARY_SETTINGS', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  editTreatmentDiarySettings({commit}, item) {
    return new Promise((resolve, reject) => {
      axios({
        url: `treatment-diary-settings/${item.id}/`,
        method: 'PATCH',
        data: item
      })
        .then(res => {
          commit('EDIT_TREATMENT_DIARY_SETTINGS', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  getTreatmentDiaryDocumentTypes({commit}) {
    return new Promise((resolve, reject) => {
      axios({
        url: `treatment-diary-document-type/`,
        method: 'GET'
      })
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  // Patient card settings
  addPatientCardSettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient-card-settings/`,
        method: 'POST',
        data: params
      })
        .then(res => {
          commit('ADD_PATIENT_CARD_SETTINGS', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  getPatientCardSettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient-card-settings/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_PATIENT_CARD_SETTINGS', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  editPatientCardSettings({commit}, item) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient-card-settings/${item.id}/`,
        method: 'PATCH',
        data: item
      })
        .then(res => {
          commit('EDIT_PATIENT_CARD_SETTINGS', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  // Employee settings
  addEmployeeSettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `employee-settings/`,
        method: 'POST',
        data: params
      })
        .then(res => {
          commit('ADD_EMPLOYEE_SETTINGS', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  getEmployeeSettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `employee-settings/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_EMPLOYEE_SETTINGS', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  editEmployeeSettings({commit}, item) {
    return new Promise((resolve, reject) => {
      axios({
        url: `employee-settings/${item.id}/`,
        method: 'PATCH',
        data: item
      })
        .then(res => {
          commit('EDIT_EMPLOYEE_SETTINGS', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  // Dental service settings
  addDentalServiceSettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `dental-service-settings/`,
        method: 'POST',
        data: params
      })
        .then(res => {
          commit('ADD_DENTAL_SERVICE_SETTINGS', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  getDentalServiceSettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `dental-service-settings/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_DENTAL_SERVICE_SETTINGS', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  editDentalServiceSettings({commit}, item) {
    return new Promise((resolve, reject) => {
      axios({
        url: `dental-service-settings/${item.id}/`,
        method: 'PATCH',
        data: item
      })
        .then(res => {
          commit('EDIT_DENTAL_SERVICE_SETTINGS', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
}
