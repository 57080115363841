import { getUserData } from '@/auth/utils'
import { $themeConfig } from '@themeConfig'
import appConstants from '@/constants/constants'

export const getAvatarSrc = () => {
  const userData = getUserData()
  return userData && userData.avatar ? userData.avatar : null
}

export const getLogoSrc = () => {
  const userData = getUserData()
  let logo = $themeConfig.app.appLogoHorizontalProfmanHealth
  if (userData && userData.logo_svg && userData.logo_svg.url) {
    logo = userData.logo_svg.url
  } else if (userData && userData.program_version === 'profman_dental') {
    logo = $themeConfig.app.appLogoHorizontalProfmanDental
  }
  return logo
}

export const getId = () => {
  const userData = getUserData()
  return userData && userData.id ? userData.id : null
}

export const getRoleType = () => {
  const userData = getUserData()
  return userData && userData.role_type ? userData.role_type : null
}

export const getFirstName = () => {
  const userData = getUserData()
  return userData && userData.first_name ? userData.first_name : null
}

export const getSystemModules = () => {
  const userData = getUserData()
  return userData && userData.system_modules ? userData.system_modules : []
}

export const getIntegratorBranchId = () => {
  const userData = getUserData()
  return userData && userData.integrator_branch_id ? userData.integrator_branch_id : null
}

export const getIntegratorId = () => {
  const userData = getUserData()
  return userData && userData.integrator_id ? parseInt(userData.integrator_id) : 0
}

export const getIntegratorName = () => {
  const userData = getUserData()
  return userData && userData.integrator_name ? userData.integrator_name : null
}

export const getProgramVersionLogo = () => {
  const userData = getUserData()
  return userData && userData.program_version === 'profman_dental'
    ? $themeConfig.app.appLogoProfmanDental
    : $themeConfig.app.appLogoProfmanHealth
}

export const getProgramVersionName = () => {
  const userData = getUserData()
  return userData && userData.program_version === 'profman_dental'
    ? $themeConfig.app.appNameProfmanDental : $themeConfig.app.appNameProfmanHealth
}

export const integratorIsDentalLaboratory = () => {
  const userData = getUserData()
  return !!(
    userData && [
      appConstants.integrator.type.DENTAL_LABORATORY, appConstants.integrator.type.CLINIC_DENTAL_LABORATORY
    ].includes(userData.integrator_type)
  )
}

export const integratorIsClinic = () => {
  const userData = getUserData()
  return !!(
    userData && [
      appConstants.integrator.type.CLINIC, appConstants.integrator.type.CLINIC_DENTAL_LABORATORY
    ].includes(userData.integrator_type)
  )
}

export const integratorIsClinicAndDentalLaboratory = () => {
  const userData = getUserData()
  return !!(
    userData && userData.integrator_type === appConstants.integrator.type.CLINIC_DENTAL_LABORATORY
  )
}

export const currentUserIsDoctor = () => {
  const roleType = getRoleType()
  return !!(roleType && roleType === 'doctor')
}

export const currentUserIsRoot = () => {
  const roleType = getRoleType()
  return !!(roleType && roleType === 'root')
}

export const subscriptionExpirationDate = () => {
  const userData = getUserData()
  return userData ? userData.subscription_expired_at : null
}

export const subscriptionMonthPrice = () => {
  const userData = getUserData()
  return userData && userData.subscription_month_price ? userData.subscription_month_price : null
}

export const smsNotificationBalance = () => {
  const userData = getUserData()
  return userData && userData.sms_notification_balance ? userData.sms_notification_balance : 0
}

export const sendingSmsToPatientIsPossible = () => {
  const userData = getUserData()
  return !!(userData && userData.sending_sms_to_patient_is_possible)
}
