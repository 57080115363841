import state from './moduleSettingsState.js'
import mutations from './moduleSettingsMutations.js'
import actions from './moduleSettingsActions.js'
import getters from './moduleSettingsGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
