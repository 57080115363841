import { mixins } from '@/mixins'

export const integratorHasCalculationLogicWithoutCalcMaterialPrice = () =>
  mixins.methods.integratorHasFixedServicePrice()

export const getPaymentDiscountAmountByTreatmentDiaries = (diaries, discountPercent) => {
  return diaries.reduce((total, tD) => total + getPaymentDiscountAmountByTreatmentDiary(tD, discountPercent), 0)
}

export const getPaymentDiscountAmountByTreatmentDiary = (treatmentDiary, discountPercent) => {
  return treatmentDiary.dental_services_history
    ? getPaymentDiscountAmountByTreatmentDiaryServices(treatmentDiary.dental_services_history, discountPercent)
    : 0
}

export const getPaymentDiscountAmountByTreatmentDiaryServices = (services, discountPercent) => {
  let discountAmount = 0
  if (services) {
    services.map(s => {
      let amountForDiscount = 0
      if (s.discount_type === 'only-service') {
        const nds = s.nds ? s.nds : (s.nds_value ? s.nds_value : null)
        amountForDiscount = mixins.methods.formatPrice((
          nds ? s.service_price + ((s.service_price / 100) * nds) : s.service_price
        ) * s.count, false)
      } else if (s.discount_type === 'service-plus-materials') {
        amountForDiscount = mixins.methods.formatPrice(s.total_price * s.count, false)
      }
      if (amountForDiscount > 0) {
        discountAmount += (discountPercent / 100) * amountForDiscount
      }
    })
  }
  return discountAmount
}
