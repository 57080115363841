import axios from '@/libs/axios'

export default {
  // Reports
  getReportForm039({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'report-form-039/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_REPORT_FORM_039', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  getReportForm039_3({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'report-form-039-3/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_REPORT_FORM_039_3', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  getReportForm037({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'report-form-037/' + params.doctor_id + '/',
        method: 'GET',
        params: params.query_params
      })
        .then(res => {
          commit('SET_REPORT_FORM_037', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  exportReportForm037({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'report-form-037-export/' + params.doctor_id + '/',
        method: 'GET',
        params: params.query_params,
        responseType: 'blob',
      })
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  exportReportForm039({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'report-form-039-export/',
        method: 'GET',
        params: params,
        responseType: 'blob',
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  exportReportForm039_3({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'report-form-039-3-export/',
        method: 'GET',
        params: params,
        responseType: 'blob',
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  // Outpatient Surgeries
  getOutpatientSurgeries({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'outpatient-surgery/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_OUTPATIENT_SURGERIES', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  getOutpatientSurgeriesExportExcel({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `outpatient-surgery-export/`, method: 'GET',
        params: params,
        responseType: 'blob',
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  // Journal of paid services
  getJournalOfPaidServices({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'paid-services-journal/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_JOURNAL_OF_PAID_SERVICES', res.data.results)
          commit('SET_JOURNAL_OF_PAID_SERVICES_COUNT', res.data.count)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  getJournalOfPaidServicesExportExcel({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `paid-services-journal-export/`, method: 'GET',
        params: params,
        responseType: 'blob',
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  getJournalOfPaidServicesTotal({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'paid-services-journal-total/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_JOURNAL_OF_PAID_SERVICES_TOTAL', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  // Certificates work performed
  getCertificatesWorkPerformed({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'certificates-work-performed/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_CERTIFICATES_WORK_PERFORMED', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  getCertificatesWorkPerformedExportExcel({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `certificates-work-performed-export/`,
        method: 'GET',
        params: params,
        responseType: 'blob',
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  // Paid medical services information
  getPaidMedicalServicesInformation({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'paid-medical-services-information/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_PAID_MEDICAL_SERVICES_INFORMATION', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  getPaidMedicalServicesInformationExportExcel({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `paid-medical-services-information-export/`,
        method: 'GET',
        params: params,
        responseType: 'blob',
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  // List of materials in bill of lading
  getWaybillMaterials({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'waybill-materials/',
        method: 'GET',
        params: params
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  // Patient recommended visits
  getPatientRecommendedVisits({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'report/patient-recommended-visit/',
        method: 'GET',
        params: params
      })
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  // Work performed (Dental laboratory)
  getWorkPerformedDL({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: 'work-performed-dl/', method: 'GET', params: params})
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  getWorkPerformedDLExportExcel({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `work-performed-dl-export/`, method: 'GET', params: params, responseType: 'blob'})
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  }
}
