// axios
import axios from 'axios'

const baseURL = process.env.VUE_APP_API_ENDPOINT

const axiosIns = axios.create({
    baseURL
})


export default axiosIns
