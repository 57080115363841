import todo from '@/router/routes/todo'
import revenue from '@/router/routes/revenue'
import analytics from '@/router/routes/analytics'

export default [
  {
    path: '/apps/calendar',
    name: 'apps-calendar',
    component: () => import('@/views/calendar/Calendar.vue'),
  },
  // *===============================================---*
  // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
  // *===============================================---*
  ...todo,
  // *===============================================---*
  // *--------- Revenue  ---------------------------------------*
  // *===============================================---*
  ...revenue,
  // *===============================================---*
  // *--------- Analytics  ---------------------------------------*
  // *===============================================---*
  ...analytics,
]
