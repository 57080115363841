export default {
  roleTypes: state => state.roleTypes,
  doctors: state => state.doctors,
  admins: state => state.admins,
  labTechnicians: state => state.lab_technicians,
  nurses: state => state.nurses,
  medicalStaff: state => state.medicalStaff,
  operators: state => state.operators,
  allUsers: state => state.allUsers,
  linesOfWork: state => state.linesOfWork,
  linesOfWorkTypes: state => state.linesOfWorkTypes,
  directions: state => state.directions,
  directionsPerPage: state => state.directionsPerPage,
  directionsPerPageCount: state => state.directionsPerPageCount,
  departmentsPerPage: state => state.departmentsPerPage,
  departmentsPerPageCount: state => state.departmentsPerPageCount,
  departments: state => state.departments,
  integratorBranches: state => state.integratorBranches,
  userIntegratorBranches: state => state.userIntegratorBranches,
  userOfficePhoneNumbersPerPage: state => state.userOfficePhoneNumbersPerPage,
  userOfficePhoneNumbersPerPageCount: state => state.userOfficePhoneNumbersPerPageCount
}
