import axios from '@/libs/axios'
import {debounce} from 'lodash'

export default {
  fetchDesignations({commit}) {
    return new Promise((resolve, reject) => {
      axios({
        url: `designations-scheduler/`, method: 'GET',
      })
        .then(res => {
          commit('setDesignations', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  editDesignation({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `designations-scheduler/${data.id}/`, method: 'PATCH', data: data
      })
        .then(res => {
          commit('designationEdited', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  addDesignation({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `designations-scheduler/`, method: 'POST', data: data
      })
        .then(res => {
          commit('designationAdded', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  removeDesignation({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `designations-scheduler/${data.id}/`, method: 'DELETE'
      })
        .then(res => {
          commit('designationRemoved', data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  fetchCabinets({commit}) {
    return new Promise((resolve, reject) => {
      axios({
        url: `cabinet/`, method: 'GET',
      })
        .then(res => {
          commit('setCabinet', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  addCabinet({commit}, cabinet) {
    return new Promise((resolve, reject) => {
      axios({
        url: `cabinet/`, method: 'POST', data: cabinet
      })
        .then(res => {
          commit('cabinetAdded', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  fetchPatientsSchedule({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `scheduler-patient/`, method: 'GET', params: params
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  addSchedulePatient({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `scheduler-patient/`, method: 'POST', data: data
      })
        .then(res => {
          commit('schedulePatientAdded', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  getSchedulePatient({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `scheduler-patient/${data.id}/`, method: 'GET', data: data
      })
        .then(res => {
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  editSchedulePatient({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `scheduler-patient/${data.id}/`, method: 'PATCH', data: data
      })
        .then(res => {
          commit('schedulePatientEdited', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  removeSchedulePatient({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `scheduler-patient/${id}/`, method: 'DELETE'
      })
        .then(res => {
          commit('SCHEDULE_PATIENT_REMOVED', id)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  moveSchedulerPatient({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `move-scheduler-patient/`,
        method: 'POST',
        data: data
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  abortSchedulerPatient({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `abort-scheduler-patient/${params.id}/`,
        method: 'DELETE',
        data: {
          abort_status_id: params.abort_status_id,
          cancellation_reason: params.cancellation_reason,
        }
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  editCabinet({commit}, cabinet) {
    return new Promise((resolve, reject) => {
      axios({
        url: `cabinet/${cabinet.id}/`, method: 'PATCH', data: cabinet
      })
        .then(res => {
          commit('cabinetEdited', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  fetchEmployeeSchedule({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `scheduler-employee/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_EMPLOYEE_SCHEDULE', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  createEmployeeSchedule({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `scheduler-employee/`,
        method: 'POST',
        data: data
      })
        .then(res => {
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  editEmployeeSchedule({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `scheduler-employee/${data.id}/`,
        method: 'PATCH',
        data: data
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  deleteEmployeeSchedule({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `scheduler-employee/`, params: {
          id: data.id
        }, method: 'DELETE'
      })
        .then(res => {
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  checkAppointmentsByEmployeeSchedulerId({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `scheduler-employee/${params.id}/exist-appointment`,
        method: 'GET',
        params: params.query_params
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  checkCabinetIsFree({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `cabinet/${params.id}/is-free`, method: 'GET', params: params.query_params
      })
        .then(res => {
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  fetchShifts({commit}) {
    return new Promise((resolve, reject) => {
      axios({
        url: `work-shift/`, method: 'GET',
      })
        .then(res => {
          commit('setShifts', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  editShift({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `work-shift/${data.id}/`,
        method: 'PATCH',
        data: data
      })
        .then(res => {
          commit('SHIFT_EDITED', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  addShift({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `work-shift/`,
        method: 'POST',
        data: data
      })
        .then(res => {
          commit('SHIFT_ADDED', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  fetchSchedulerDoctorsGroups({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `scheduler-doctors-groups/`, method: 'GET', params: params
      })
        .then(res => {
          commit('setSchedulerDoctorsGroups', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  fetchDoctorShiftsForAppointmentCalendar({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `doctor-shifts-for-appointment-calendar/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('setDoctorShiftsForAppointmentCalendar', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  deleteShift({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `work-shift/${id}/`,
        method: 'DELETE'
      })
        .then(res => {
          commit('SHIFT_DELETED', id)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  fetchDoctorWeekendDate({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `weekends_doctors_groups/`, method: 'GET', params: params
      })
        .then(res => {
          commit('setDoctorWeekendDates', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  fetchReserves({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `scheduler-backup-visit-record/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_RESERVES', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  fetchLoadingDoctors: debounce(({commit}, params) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `workload-doctors/`, method: 'GET', params: params
      })
        .then(res => {
          commit('setLoadingDoctors', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  }, 500),
  fetchPatientsAwaitStatus({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `scheduler-patient-await-status/`, method: 'GET', params: params
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  fetchPatientsOnlineStatus({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `scheduler-patient-online-status/`, method: 'GET', params: params
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  fetchPatientsChangeStatus({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `scheduler-patient-change-status/`, method: 'GET', params: params
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  addReserve({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `scheduler-backup-visit-record/`,
        method: 'POST',
        data: data
      })
        .then(res => {
          commit('reserveAdded', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  editReserve({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `scheduler-backup-visit-record/${data.id}/`,
        method: 'PATCH',
        data: data
      })
        .then(res => {
          commit('reserveEdited', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  deleteReserve({commit}, config) {
    return new Promise((resolve, reject) => {
      axios({
        url: `scheduler-backup-visit-record/${config.data.id}/`,
        method: 'DELETE'
      })
        .then(res => {
          commit('itemRemoved', config)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  createPatientFromReserve({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `scheduler-backup-visit-record-patient/${params.reserve_id}/`,
        method: 'PATCH',
        data: params.data
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  requestAction({commit}, config) {
    return new Promise((resolve, reject) => {
      axios(config.request)
        .then(res => {
          if (config.hasOwnProperty('successCallback') && config.request.method !== 'DELETE') {
            config.successCallback(commit, res)
          }
          if (config.hasOwnProperty('successCallback') && config.request.method === 'DELETE') {
            config.successCallback(commit, config.request.data)
          }
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  fetchCabinetsForUser({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `cabinet_for_user/`, method: 'GET', params: params
      })
        .then(res => {
          commit('setCabinetsForUser', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  suitableWorkShiftForUser({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `user-has-suitable-work-shift/` + params.user_id + '/', method: 'GET', params: params
      })
        .then(res => {
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  getWorkingDoctorsOnDay({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `working-doctors-on-day/`, method: 'GET', params: params
      })
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  // Doctors for dropdown
  getDoctors({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'scheduler/doctor/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_DOCTORS', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  // Patient calendar free time
  fetchPatientCalendarFreeTime({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient-calendar-free-time/`,
        method: 'GET',
        params: params
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  // Employee work day
  getEmployeeCurrentWorkDay() {
    return new Promise((resolve, reject) => {
      axios({
        url: `employee-current-work-day`,
        method: 'GET'
      })
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  startEmployeeWorkDay() {
    return new Promise((resolve, reject) => {
      axios({
        url: `employee-work-day/`,
        method: 'POST'
      })
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  updateEmployeeWorkDay({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `employee-work-day/${data.id}/`,
        method: 'PATCH',
        data: data
      })
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  getEmployeeWorkDays({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `employee-work-day/`,
        method: 'GET',
        params: params
      })
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  // Additional work shift
  addAdditionalWorkShift({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `additional-work-shift/`,
        method: 'POST',
        data: data
      })
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  editAdditionalWorkShift({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `additional-work-shift/${data.id}/`,
        method: 'PATCH',
        data: data
      })
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  removeAdditionalWorkShift({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({url: `additional-work-shift/${id}/`, method: 'DELETE'})
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
}
