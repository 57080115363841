import {
  entityDictionaryproducts, entityDictionarysuppliers, entityHistoryOrders, entityOrder, entityOrderBucket
} from '@/libs/acl/entities'

export default [
  {
    path: '/apps/application/suppliers',
    name: 'application-suppliers',
    component: () => import('@/views/application/suppliers/ApplicationSuppliers.vue'),
    meta: {
      resource: entityDictionarysuppliers,
      action: 'read',
      title: 'Suppliers'
    }
  },
  {
    path: '/apps/application/products',
    name: 'application-products',
    component: () => import('@/views/application/products/ApplicationProducts.vue'),
    meta: {
      resource: entityDictionaryproducts,
      action: 'read',
      title: 'Products',
    }
  },
  {
    path: '/apps/application/bucket',
    name: 'application-bucket',
    component: () => import('@/views/application/bucket/ApplicationBucket.vue'),
    meta: {
      resource: entityOrderBucket,
      action: 'read',
      title: 'Cart',
    }
  },
  {
    path: '/apps/application/orders',
    name: 'application-orders',
    component: () => import('@/views/application/orders/ApplicationOrders.vue'),
    meta: {
      resource: entityHistoryOrders,
      action: 'read',
      title: 'Orders',
    }
  },
  {
    path: '/apps/application/orders/:orderId/view',
    name: 'application-order-view',
    component: () => import('@/views/application/orders/ApplicationOrderView.vue'),
    meta: {
      resource: entityOrder,
      action: 'read',
      title: 'Order view'
    }
  },
]
