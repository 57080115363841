import axios from "@/libs/axios"

export default {
  // Tasks
  getTasks({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'tasks-me/',
        method: 'GET',
        params: params
      })
      .then(res => {
        commit('SET_TASKS', res.data.results)
        resolve(res)
      })
      .catch(error => {
        reject({message: error})
      })
    })
  },
  createTask({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'tasks/',
        method: 'POST',
        data: data,
        headers: {'Content-Type': 'multipart/form-data'}
      })
      .then(res => {
        commit('ADD_TASK', res.data)
        resolve(res)
      })
      .catch(error => {
        reject({message: error})
      })
    })
  },
  deleteTask({commit}, taskId) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'tasks/' + taskId,
        method: 'DELETE'
      })
      .then(res => {
        commit('DELETE_TASK', taskId)
        resolve(res)
      })
      .catch(error => {
        reject({message: error})
      })
    })
  },
  editTask({commit}, task) {
    return new Promise((resolve, reject) => {
      axios({
        url: `tasks/${task.get('id')}/`,
        method: 'PATCH',
        data: task,
        headers: {'Content-Type': 'multipart/form-data'}
      })
      .then(res => {
        commit('EDIT_TASK', res.data)
        resolve(res)
      })
      .catch(error => {
        reject({message: error})
      })
    })
  },
  // Task Statuses
  getStatuses({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'tasks-status/',
        method: 'GET',
        params: params
      })
      .then(res => {
        commit('SET_STATUSES', res.data)
        resolve(res)
      }).catch(error => {
        reject({message: error})
      })
    })
  },
  createStatus({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'tasks-status/',
        method: 'POST',
        data: data
      })
      .then(res => {
        commit('ADD_STATUS', res.data)
        resolve(res)
      }).catch(error => {
        reject({message: error})
      })
    })
  },
  deleteStatus({commit}, statusId) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'tasks-status/' + statusId,
        method: 'DELETE'
      })
      .then(res => {
        commit('DELETE_STATUS', statusId)
        resolve(res)
      })
      .catch(error => {
        reject({message: error})
      })
    })
  },
  // Task Tags
  getTags({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'tasks-tags/',
        method: 'GET',
        params: params
      })
      .then(res => {
        commit('SET_TAGS', res.data)
        resolve(res)
      }).catch(error => {
        reject({message: error})
      })
    })
  },
  createTag({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'tasks-tags/',
        method: 'POST',
        data: data
      })
      .then(res => {
        commit('ADD_TAG', res.data)
        resolve(res)
      }).catch(error => {
        reject({message: error})
      })
    })
  },
  deleteTag({commit}, tagId) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'tasks-tags/' + tagId,
        method: 'DELETE'
      })
      .then(res => {
        commit('DELETE_TAG', tagId)
        resolve(res)
      })
      .catch(error => {
        reject({message: error})
      })
    })
  },
  // Task Priorities
  getPriorities({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'tasks-priority/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_PRIORITIES', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  createPriority({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'tasks-priority/',
        method: 'POST',
        data: data
      })
        .then(res => {
          commit('ADD_PRIORITY', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  deletePriority({commit}, priorityId) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'tasks-priority/' + priorityId,
        method: 'DELETE'
      })
        .then(res => {
          commit('DELETE_PRIORITY', priorityId)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  // Task Filters
  changeTagFilter({commit}, data) {
    commit('CHANGE_TAG_FILTER', data)
  },
  changeStatusFilter({commit}, data) {
    commit('CHANGE_STATUS_FILTER', data)
  },
  changeDueDateCategoryFilter({commit}, data) {
    commit('CHANGE_DUE_DATE_CATEGORY_FILTER', data)
  },
  changeDueDateFilter({commit}, data) {
    commit('CHANGE_DUE_DATE_FILTER', data)
  },
  changeCategoryTypeFilter({commit}, data) {
    commit('CHANGE_CATEGORY_TYPE_FILTER', data)
  },
  changeEmployeeFilter({commit}, data) {
    commit('CHANGE_EMPLOYEE_FILTER', data)
  },
  changePriorityFilter({commit}, data) {
    commit('CHANGE_PRIORITY_FILTER', data)
  },
}
