import {entityPatientPayment} from '@/libs/acl/entities'

export default [
  {
    path: '/apps/wage',
    name: 'apps-wage',
    component: () => import('@/views/wage/Wage.vue'),
    meta: {
      contentClass: 'wage-application',
      resource: entityPatientPayment,
      action: 'read',
      title: 'Wage calculation'
    },
  }
]
