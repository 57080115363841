<template>
  <div class="toastification">
    <div class="d-flex align-items-start">
      <b-avatar
        variant="info"
        size="1.8rem"
        class="mr-75 flex-shrink-0"
      >
        <feather-icon
          icon="AlertTriangleIcon"
          size="15"
        ></feather-icon>
      </b-avatar>
      <div class="d-flex flex-grow-1">
        <div class="w-100">
          <h5
            v-if="title"
            class="mb-0 font-weight-bolder toastification-title"
            :class="`text-info`"
            v-text="title"
          ></h5>
          <h5
            v-if="serviceName"
            class="mt-1 mb-1 text-body font-weight-bold"
          >{{ serviceName }}</h5>
          <hr>
          <div class="d-flex align-items-center">
            <span class="text-body mr-50 font-weight-bold">Источник:</span>
            <b-badge
              pill
              class="float-right"
              :variant="source ? 'light-info' : 'light-warning'"
            >
              <small
                class="d-inline-block font-weight-bold"
                v-text="source ? capitalizeFirstLetter(source) : 'Неизвестен'"
                :class="source ? 'text-info' : 'text-warning'"
              ></small>
            </b-badge>
          </div>
          <div
            v-if="patientFullName"
            class="d-flex align-items-center"
          >
            <span class="text-body mr-50 font-weight-bold">ФИО:</span>
            <small
              class="d-inline-block text-body"
              v-text="patientFullName"
            ></small>
          </div>
          <div
            v-if="formattedPhone"
            class="d-flex align-items-center"
          >
            <span class="text-body mr-50 font-weight-bold">Телефон:</span>
            <small
              class="d-inline-block text-body"
              v-text="formattedPhone"
            ></small>
          </div>
          <div
            v-if="time"
            class="d-flex align-items-center mb-1"
          >
            <span class="text-body mr-50 font-weight-bold">Время:</span>
            <small
              class="d-inline-block text-body"
              v-text="time"
            ></small>
          </div>
          <div
            @click="onLeadLinkClicked"
            @click.middle="onLeadLinkClicked"
            @contextmenu="onLeadLinkClicked"
          >
            <b-link :to="{path: '/apps/leads'}">
              <small
                class="d-inline-block"
                v-text="'Перейти к лиду'"
              ></small>
            </b-link>
          </div>
          <div
            v-if="chatId"
            @click="onChatLinkClicked"
            @click.middle="onChatLinkClicked"
            @contextmenu="onChatLinkClicked"
            class="mt-50"
          >
            <b-link :to="{path: '/apps/patient-chat'}">
              <small
                class="d-inline-block"
                v-text="'Перейти к чату'"
              ></small>
            </b-link>
          </div>
        </div>
        <span
          class="cursor-pointer toastification-close-icon ml-auto "
          @click="$emit('close-toast')"
        >
          <feather-icon
            icon="XIcon"
            class="text-body ml-1"
          ></feather-icon>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar, BBadge, BLink } from 'bootstrap-vue'
import { computed } from 'vue'
import { addDataToLocalStorageByPrefix } from '@/composables/localStorageHelper'
import appConstants from '@/constants/constants'

export default {
  name: 'ToastificationNewLead',
  components: {
    BBadge,
    BAvatar, BLink
  },
  props: {
    id: Number,
    date: Number,
    source: String,
    patientFullName: String,
    phoneCode: String,
    phone: String,
    time: String,
    chatId: Number
  },
  setup(props) {
    const serviceName = `Поступил новый лид`
    const title = `Внимание!`
    const formattedPhone = computed(() => {
      if (props.phoneCode && props.phone) {
        const number = props.phone
        let result = props.phone
        if (number.length === 10) {
          result = '(' + number.substring(0, 3) + ') ' + number.substring(3, 6) + '-' + number.substring(6, 8)
            + '-' + number.substring(8, 10)
        } else if (number.length === 9) {
          result = '(' + number.substring(0, 2) + ') ' + number.substring(2, 5) + '-' + number.substring(5, 7)
            + '-' + number.substring(7, 9)
        }
        return '+' + props.phoneCode + ' ' + result
      }
      return null
    })

    const onLeadLinkClicked = () => addDataToLocalStorageByPrefix(
      appConstants.lead.onlineNotif.APP_NEW_LEADS_LOCAL_STORAGE_KEY, {id: props.id, date: props.date}
    )
    const onChatLinkClicked = () => addDataToLocalStorageByPrefix(
      appConstants.chat.onlineNotif.APP_NEW_LEADS_LOCAL_STORAGE_KEY, {id: props.chatId, type: props.source}
    )

    return {
      title,
      formattedPhone,
      serviceName,
      onLeadLinkClicked,
      onChatLinkClicked
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
