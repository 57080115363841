import Vue from 'vue'
import {mixins} from '@/mixins'

const setOptions = (state, all_doctors, selected_doctors) => {
  let colors = state.calendarColors
  all_doctors.value.forEach(doctor => {
    if (!state.calendarOptions.find(option => option.id === doctor.id)) {
      state.calendarOptions.push({
        id: doctor.id,
        name: doctor.full_name,
        color: colors.shift()
      })
    }
  })

  let idsForRemove = []
  state.calendarOptions.map(co => {
    const idx = all_doctors.value.findIndex(d => d.id === co.id)
    if (idx === -1) {
      idsForRemove.push(co.id)
    }
  })
  if (idsForRemove.length) {
    idsForRemove.map(id => {
      const idx = state.calendarOptions.findIndex(co => co.id === id)
      if (idx > -1 ) {
        state.calendarOptions.splice(idx, 1)
      }
    })
  }

  selected_doctors.value.forEach(doctor => {
    if (!state.selectedCalendars.includes(doctor.id)) {
      state.selectedCalendars.push(doctor.id)
    }
  })

  idsForRemove = []
  state.selectedCalendars.forEach(selectedItem => {
    if (!selected_doctors.value.find(doctor => doctor.id === selectedItem)) {
      idsForRemove.push(selectedItem)
    }
  })

  if (idsForRemove.length) {
    idsForRemove.map(id => {
      const idx = state.selectedCalendars.findIndex(sc => sc === id)
      if (idx > -1 ) {
        state.selectedCalendars.splice(idx, 1)
      }
    })
    idsForRemove = []
  }
}
export default {
  setDesignations(state, items) {
    state.designations = items
  },
  saveCalendarView(state, val) {
    state.scheduleCalendarView = val
  },
  CLEAR_CALENDAR_VIEW(state) {
    state.scheduleCalendarView = null
  },
  designationEdited(state, item) {
    const index = state.designations.findIndex(element => element.id === item.id)
    Vue.set(state.designations, index, item)
  },
  designationAdded(state, item) {
    state.designations.push(item)
  },
  designationRemoved(state, item) {
    state.designations.splice(
      state.designations.findIndex(d => d.id === item.id),
      1
    )
  },
  setDesignation(state, item) {
    state.designation = item
  },
  setCabinet(state, items) {
    state.cabinets = items
  },
  setSingleCabinet(state, item) {
    state.cabinet = item
  },
  cabinetAdded(state, cabinet) {
    state.cabinets.push(cabinet)
  },
  cabinetEdited(state, cabinet) {
    const index = state.cabinets.findIndex(element => element.id === cabinet.id)
    Vue.set(state.cabinets, index, cabinet)
  },
  emptyCabinet(state) {
    state.cabinet = null
  },
  emptyDesignation(state) {
    state.designation = null
  },
  schedulePatientAdded(state, data) {
    state.schedulePatientList.push(data)
  },
  setSchedulePatientList(state, data) {
    setOptions(state, data.all_doctors, data.selected_doctors)
    state.schedulePatientList = data.schedules
  },
  SCHEDULE_PATIENT_REMOVED(state, scheduleId) {
    state.schedulePatientList.splice(
      state.schedulePatientList.findIndex(s => s.id === scheduleId),
      1
    )
  },
  refreshOptions(state) {
    const idxToDelete = []
    state.calendarOptions.forEach((op, index) => {
      const idx = state.schedulePatientList.findIndex(item => item.doctor.id === op.id)
      if (idx === -1) {
        idxToDelete.push(index)
      }
    })
    idxToDelete.forEach(i => {
      state.calendarOptions.splice(i, 1)
    })
  },
  setCalendarOptions(state, data) {
    setOptions(state, data.all_doctors, data.selected_doctors)
  },
  CLEAR_CALENDAR_OPTIONS(state) {
    state.calendarOptions = []
  },
  schedulePatientEdited(state, data) {
    const index = state.schedulePatientList.findIndex(element => element.id === data.id)
    Vue.set(state.schedulePatientList, index, data)
  },
  setSchedulePatient(state, schedule) {
    state.schedulePatient = schedule
  },
  resetSchedulePatient(state) {
    state.schedulePatient = null
  },
  SET_SELECTED_EVENTS(state, val) {
    state.selectedCalendars = val
  },
  CLEAR_SELECTED_EVENTS(state) {
    state.selectedCalendars = []
  },
  SET_EMPLOYEE_SCHEDULE(state, val) {
    state.employeeSchedule = []
    val.forEach(schedule => {
      schedule.users = schedule.users.filter(u => u.data.length > 0)
      state.employeeSchedule.push(schedule)
    })
  },
  setShifts(state, data) {
    state.shifts = data
  },
  SHIFT_ADDED(state, item) {
    state.shifts.push(item)
    state.shifts = state.shifts.sort(
      (shiftOne, shiftTwo) => shiftOne.user.id < shiftTwo.user.id
        ? -1
        : (shiftOne.user.id > shiftTwo.user.id ? 1 : 0)
    )
  },
  SHIFT_EDITED(state, data) {
    const index = state.shifts.findIndex(element => element.id === data.id)
    Vue.set(state.shifts, index, data)
  },
  setShift(state, data) {
    state.shift = data
  },
  emptyShift(state) {
    state.shift = null
  },
  SHIFT_DELETED(state, id) {
    state.shifts.splice(state.shifts.findIndex(w => w.id === id), 1)
  },
  setSchedulerDoctorsGroups(state, data) {
    state.schedulerDoctorsGroups = data
  },
  setDoctorShiftsForAppointmentCalendar(state, data) {
    state.doctorShiftsForAppointmentCalendar = data
  },
  setDoctorWeekendDates(state, data) {
    state.doctorWeekendDates = data
  },
  setSelectedServiceTime(state, time) {
    state.selectedServiceTime = time
  },
  SET_RESERVES(state, visits) {
    state.reserves = visits
  },
  setLoadingDoctors(state, data) {
    state.loadingDoctors = data
  },
  setLoadingDoctorsCount(state, count) {
    state.loadingDoctorsCount = count
  },
  setActiveRange(state, range) {
    state.activeRange = range
  },
  SET_LIST_DAY_EXTENDED_CALENDAR_DATE_STATE(state, data) {
    state.listDayExtendedCalendarDateState = data
  },
  setCalendarDateSelected(state, date) {
    state.calendarDateSelected = date
  },
  reserveAdded(state, reserve) {
    state.reserves.push(reserve)
    state.reserves = state.reserves.sort(
      (rOne, rTwo) => rOne.desired_date > rTwo.desired_date
        ? -1
        : (rOne.desired_date < rTwo.desired_date ? 1 : 0)
    )
  },
  reserveEdited(state, reserve) {
    const index = state.reserves.findIndex(element => element.id === reserve.id)
    Vue.set(state.reserves, index, reserve)
    state.reserves = state.reserves.sort(
      (rOne, rTwo) => rOne.desired_date > rTwo.desired_date
        ? -1
        : (rOne.desired_date < rTwo.desired_date ? 1 : 0)
    )
  },
  itemRemoved(state, config) {
    state[config.key].splice(
      state[config.key].findIndex(item => config.data.id === item.id),
      1
    )
  },
  setCabinetsForUser(state, items) {
    state.cabinetsForUser = items
  },
  setComponentToRender(state, item) {
    state.componentToRender = item
  },
  CLEAR_COMPONENT_TO_RENDER(state) {
    state.componentToRender = null
  },
  SET_SELECTED_DOCTOR(state, doctorId) {
    state.selectedDoctorId = doctorId
  },
  CLEAR_SELECTED_DOCTOR(state) {
    state.selectedDoctorId = null
  },
  SET_SELECTED_DOCTORS(state, val) {
    mixins.methods.addUserDoctorsToStorage(val)
    state.selectedDoctors = val
  },
  CLEAR_SELECTED_DOCTORS(state) {
    state.selectedDoctors = []
  },
  SET_ACTIVE_TAB(state, val) {
    state.activeTab = val
  },
  CLEAR_ACTIVE_TAB(state) {
    state.activeTab = null
  },
  SET_NEW_PATIENT_APPOINTMENT_INFO(state, data) {
    state.newPatientAppointmentInfo = data
  },
  CLEAR_NEW_PATIENT_APPOINTMENT_INFO(state) {
    state.newPatientAppointmentInfo = null
  },
  SET_NEW_PATIENT_APPOINTMENT_WATCHER(state, watcher) {
    state.newPatientAppointmentInfoWatcher = watcher
  },
  // Doctors
  SET_DOCTORS(state, data) {
    state.doctors = data
    const doctorsFromStorage = mixins.methods.getUserDoctorsFromStorage()
    if (doctorsFromStorage) {
      doctorsFromStorage.map(d => data.map(doctor => {
        d.avatar = doctor.id === d.id ? doctor.avatar : d.avatar
        d.work_shifts = doctor.id === d.id ? doctor.work_shifts : d.work_shifts
        d.additional_work_shift = doctor.id === d.id ? doctor.additional_work_shift : d.additional_work_shift
        d.full_name_initials = doctor.id === d.id ? doctor.full_name_initials : d.full_name_initials
      }))
      mixins.methods.addUserDoctorsToStorage(doctorsFromStorage)
      state.selectedDoctors = doctorsFromStorage
    }
  },
  // employee schedule calendar
  SET_SELECTED_DAYS_IN_CALENDAR(state, data) {
    state.selectedDaysInEmployeeScheduleCalendar = data
  },
  SET_COPIED_EMPLOYEE_SCHEDULE_STATUS_IN_CALENDAR(state, status) {
    state.copiedEmployeeScheduleStatusInCalendar = status
  },
}
