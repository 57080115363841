export default {
  patientsPayments: state => state.patientsPayments,
  patientsPaymentsCount: state => state.patientsPaymentsCount,
  getCashRegisterTotal: state => state.cash_register_total,
  getTotalByLineOfWork: state => state.total_by_line_of_work,
  getTotalBySnapshot: state => state.total_by_snapshot,
  getTotalBySelling: state => state.total_by_selling,
  getTotalByPrepayment: state => state.total_by_prepayment,
  getTotalByDiscount: state => state.total_by_discount,
  getDebtors: state => state.debtors,
  getInsurances: state => state.insurances,
  insurancesPerPage: state => state.insurancesPerPage,
  insurancesPerPageTotalCount: state => state.insurancesPerPageTotalCount,
  getCertificates: state => state.certificates,
  certificatesPerPage: state => state.certificatesPerPage,
  certificatesPerPageTotalCount: state => state.certificatesPerPageTotalCount,
  getCashlessPayments: state => state.cashlessPayments,
  getCashRegisterSections: state => state.cashRegisterSections && state.cashRegisterSections.length
    ? state.cashRegisterSections : [],
  billingStatements: state => state.billingStatements,
  billingStatementsCount: state => state.billingStatementsCount
}
