import {entityChat} from '@/libs/acl/entities'
import appConstants from '@/constants/constants'

export default [
  {
    path: '/apps/user-chat',
    name: appConstants.router.chat.APP_USER_CHAT,
    component: () => import('@/views/components/dental-chat/DentalChat.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'dental-chat-application chat-application',
      resource: entityChat,
      action: 'read',
      title: 'Work chat'
    },
  },
  {
    path: '/apps/patient-chat',
    name: appConstants.router.chat.APP_PATIENT_CHAT,
    component: () => import('@/views/components/dental-chat/DentalChat.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'dental-chat-application chat-application',
      resource: entityChat,
      action: 'read',
      title: 'Chat with patients'
    },
  },
]
