import axios from '@/libs/axios'
import { getId } from '@/composables/currentUser'

export default {
  // Role types
  getRoleTypes({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'role-type/',
        method: 'GET',
        params: params
      })
      .then(res => {
        commit('SET_ROLE_TYPES', res.data)
        resolve(res)
      })
      .catch(e => reject({message: e}))
    })
  },
  // Doctors
  getDoctors({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'doctor/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_DOCTORS', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  // Admins
  getAdmins({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'admin/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_ADMINS', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  // Lab Technicians
  getLabTechnicians({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'lab-technician/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_LAB_TECHNICIANS', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  // Nurses
  getNurses({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'nurse/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_NURSES', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  // Medical staff
  getMedicalStaff({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'medical-staff/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_MEDICAL_STAFF', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  // Operators
  getOperators({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'operator/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_OPERATORS', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  // All users
  getAllUsers({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'all-users/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_ALL_USERS', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  // Lines of work
  getLinesOfWork({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'employee-line-of-work/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_EMPLOYEE_LINES_OF_WORK', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  addLineOfWork({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.post('employee-line-of-work/', item)
        .then(res => {
          commit('ADD_LINE_OF_WORK', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  editLineOfWork({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`employee-line-of-work/${item.id}/`, item)
        .then(res => {
          commit('EDIT_LINE_OF_WORK', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  removeLineOfWork({commit}, itemId) {
    return new Promise((resolve, reject) => {
      axios.delete(`employee-line-of-work/${itemId}/`)
        .then(res => {
          commit('REMOVE_LINE_OF_WORK', itemId)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  // Lines of work types
  getLinesOfWorkTypes({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'employee-line-of-work-type/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_EMPLOYEE_LINES_OF_WORK_TYPES', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  // Direction
  getAllDirections({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'all-direction/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_DIRECTIONS_PER_PAGE', res.data.results)
          commit('SET_DIRECTIONS_PER_PAGE_COUNT', res.data.count)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  getDirectionForDropdown({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'direction-for-dropdown/',
        method: 'GET',
        params: params
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  getDirections({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'direction/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_DIRECTIONS', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  addDirection({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.post('all-direction/', item)
        .then(res => {
          commit('ADD_DIRECTION', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  editDirection({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`all-direction/${item.id}/`, item)
        .then(res => {
          commit('EDIT_DIRECTION', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  removeDirection({commit}, itemId) {
    return new Promise((resolve, reject) => {
      axios.delete(`all-direction/${itemId}/`)
        .then(res => {
          commit('REMOVE_DIRECTION', itemId)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  // Department
  getDepartmentsPerPage({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'department/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_DEPARTMENTS_PER_PAGE', res.data.results)
          commit('SET_DEPARTMENTS_PER_PAGE_COUNT', res.data.count)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  addDepartment({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.post('department/', item)
        .then(res => {
          commit('ADD_DEPARTMENT', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  editDepartment({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`department/${item.id}/`, item)
        .then(res => {
          commit('EDIT_DEPARTMENT', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  removeDepartment({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`department/${id}/`)
        .then(res => {
          commit('REMOVE_DEPARTMENT', id)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  getDepartments({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'department/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_DEPARTMENTS', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  // Integrator branches
  getIntegratorBranches({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'dictionary/integrator-branch-for-dropdown/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_INTEGRATOR_BRANCHES', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  getUserIntegratorBranches({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'dictionary/user-integrator-branch-for-dropdown/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_USER_INTEGRATOR_BRANCHES', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  // User office phone numbers
  getUserOfficePhoneNumbers({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `user/${params.userId}/office-phone-number`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_USER_OFFICE_PHONE_NUMBERS_PER_PAGE', res.data.results)
          commit('SET_USER_OFFICE_PHONE_NUMBERS_PER_PAGE_COUNT', res.data.count)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  addUserOfficePhoneNumber({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.post(`user/${item.user_id}/office-phone-number`, item)
        .then(res => {
          commit('USER_OFFICE_PHONE_NUMBER_ADD', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  editUserOfficePhoneNumber({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`user/${item.user_id}/office-phone-number/${item.id}`, item)
        .then(res => {
          commit('USER_OFFICE_PHONE_NUMBER_EDIT', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  deleteUserOfficePhoneNumber({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.delete(`user/${item.userId}/office-phone-number/${item.id}`)
        .then(res => {
          commit('USER_OFFICE_PHONE_NUMBER_DELETE', item.id)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  changeUserOfficePhoneNumberIsMainAttr({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `user/${params.user_id}/office-phone-number/${params.id}/is-main`,
        method: 'PUT',
        data: params.data
      })
        .then(res => {
          commit('USER_OFFICE_PHONE_NUMBER_EDIT_IS_MAIN', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  // change online status
  changeOnlineStatus({commit}) {
    const userId = getId()
    if (userId) {
      return new Promise((resolve, reject) => {
        axios({url: `user/${userId}/online`, method: 'PATCH'})
          .then(res => resolve(res))
          .catch(error => reject({message: error}))
      })
    }
  },
}
