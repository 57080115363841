import {entityDentalservicesreference} from '@/libs/acl/entities'

export default [
  {
    title: 'Medical services calculation',
    icon: 'ClipboardIcon',
    resource: entityDentalservicesreference,
    action: 'read',
    route: 'apps-service-calculation',
  }
]
