import moment from 'moment';

export const treatmentDiaryMixins = {
  methods: {
    init() {
      this.initMoment()
    },
    initMoment() {
      moment.locale('ru')
    },
    treatmentDiaryTitle(treatmentDiary, selectedTreatmentPlansNames) {
      this.init()
      const diaryDate = moment(parseInt(treatmentDiary.date)).format('lll')
      const diaryDoctorFullName = treatmentDiary.doctor ? treatmentDiary.doctor.full_name : ''
      const diaryDiagnosisCustomName = treatmentDiary.diagnosis_custom_name
        ? treatmentDiary.diagnosis_custom_name
        : treatmentDiary.diagnoses.map(diagnosis => diagnosis.full_name).join(', ')
      const items = []
      if (diaryDate) {
        items.push(diaryDate)
      }
      if (diaryDoctorFullName) {
        items.push(diaryDoctorFullName)
      }
      if (diaryDiagnosisCustomName) {
        items.push(diaryDiagnosisCustomName)
      }
      if (selectedTreatmentPlansNames) {
        items.push(selectedTreatmentPlansNames)
      }
      return items.join(' | ')
    }
  }
}
