import {entityDentalservicesreference} from '@/libs/acl/entities'

export default [
  {
    path: '/apps/service-calculation',
    name: 'apps-service-calculation',
    component: () => import('@/views/service-calculation/ServiceCalculation'),
    meta: {
      contentClass: 'service-calculation-application',
      resource: entityDentalservicesreference,
      action: 'read',
      title: 'Medical services calculation'
    },
  }
]
