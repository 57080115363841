import axios from '@/libs/axios'

export default {
  // Purchase order
  getPurchaseOrders({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'dental-laboratory/purchase-order/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_PURCHASE_ORDERS_PER_PAGE', res.data.results)
          commit('SET_PURCHASE_ORDERS_PER_PAGE_COUNT', res.data.count)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  getPurchaseOrder({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `dental-laboratory/purchase-order/${id}`,
        method: 'GET'
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  addPurchaseOrder({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `dental-laboratory/purchase-order/`,
        method: 'POST',
        headers: {'Content-Type': 'multipart/form-data'},
        data: data
      })
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  editPurchaseOrder({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `dental-laboratory/purchase-order/${data.get('id')}/`,
        method: 'PATCH',
        headers: {'Content-Type': 'multipart/form-data'},
        data: data
      })
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  deletePurchaseOrder({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `dental-laboratory/purchase-order/${id}`,
        method: 'DELETE'
      })
        .then(res => {
          commit('REMOVE_PURCHASE_ORDER', id)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  changePurchaseOrderStatus({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `dental-laboratory/purchase-order/${params.id}/status`,
        method: 'PATCH',
        data: params.data
      })
        .then(res => {
          commit('CHANGE_PURCHASE_ORDER_STATUS', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  changePurchaseOrderAct({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `dental-laboratory/purchase-order/${params.purchaseOrderId}/act`,
        method: 'PATCH',
        data: params.data
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  getPurchaseOrderInfo({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `dental-laboratory/purchase-order-info/${id}`,
        method: 'GET'
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  getPurchaseOrderActInvoiceExcelFile({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `dental-laboratory/purchase-order/${params.purchaseOrderId}/act-invoice-export`, method: 'GET',
        responseType: 'blob'
      })
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  getPurchaseOrderActWaybillExcelFile({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `dental-laboratory/purchase-order/${params.purchaseOrderId}/act-waybill-export`, method: 'GET',
        responseType: 'blob'
      })
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  // Implant system
  getImplantSystemPerPage({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'dental-laboratory/implant-system/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_IMPLANT_SYSTEM_PER_PAGE', res.data.results)
          commit('SET_IMPLANT_SYSTEM_PER_PAGE_COUNT', res.data.count)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  getDentalLabImplantSystems({commit}, dentalLabId) {
    return new Promise((resolve, reject) => {
      axios({
        url: `dental-laboratory/${dentalLabId}/implant-system`,
        method: 'GET'
      })
        .then(res => {
          commit('SET_DENTAL_LAB_IMPLANT_SYSTEMS', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  addImplantSystem({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.post('dental-laboratory/implant-system/', item)
        .then(res => {
          commit('ADD_IMPLANT_SYSTEM', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  editImplantSystem({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`dental-laboratory/implant-system/${item.id}/`, item)
        .then(res => {
          commit('EDIT_IMPLANT_SYSTEM', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  removeImplantSystem({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`dental-laboratory/implant-system/${id}/`)
        .then(res => {
          commit('REMOVE_IMPLANT_SYSTEM', id)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  // Implant work type
  getImplantWorkTypePerPage({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'dental-laboratory/implant-work-type/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_IMPLANT_WORK_TYPE_PER_PAGE', res.data.results)
          commit('SET_IMPLANT_WORK_TYPE_PER_PAGE_COUNT', res.data.count)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  getDentalLabImplantWorkTypes({commit}, dentalLabId) {
    return new Promise((resolve, reject) => {
      axios({
        url: `dental-laboratory/${dentalLabId}/implant-work-type`,
        method: 'GET'
      })
        .then(res => {
          commit('SET_DENTAL_LAB_IMPLANT_WORK_TYPES', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  addImplantWorkType({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.post('dental-laboratory/implant-work-type/', item)
        .then(res => {
          commit('ADD_IMPLANT_WORK_TYPE', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  editImplantWorkType({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`dental-laboratory/implant-work-type/${item.id}/`, item)
        .then(res => {
          commit('EDIT_IMPLANT_WORK_TYPE', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  removeImplantWorkType({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`dental-laboratory/implant-work-type/${id}/`)
        .then(res => {
          commit('REMOVE_IMPLANT_WORK_TYPE', id)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  // Material type
  getMaterialTypePerPage({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'dental-laboratory/material-type/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_MATERIAL_TYPE_PER_PAGE', res.data.results)
          commit('SET_MATERIAL_TYPE_PER_PAGE_COUNT', res.data.count)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  getDentalLabMaterialTypes({commit}, dentalLabId) {
    return new Promise((resolve, reject) => {
      axios({url: `dental-laboratory/${dentalLabId}/material-type`, method: 'GET'})
        .then(res => {
          commit('SET_DENTAL_LAB_MATERIAL_TYPES', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  addMaterialType({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.post('dental-laboratory/material-type/', item)
        .then(res => {
          commit('ADD_MATERIAL_TYPE', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  editMaterialType({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`dental-laboratory/material-type/${item.id}/`, item)
        .then(res => {
          commit('EDIT_MATERIAL_TYPE', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  removeMaterialType({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`dental-laboratory/material-type/${id}/`)
        .then(res => {
          commit('REMOVE_MATERIAL_TYPE', id)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  // Work type
  getWorkTypePerPage({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'dental-laboratory/work-type/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_WORK_TYPE_PER_PAGE', res.data.results)
          commit('SET_WORK_TYPE_PER_PAGE_COUNT', res.data.count)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  getDentalLabWorkTypes({commit}, dentalLabId) {
    return new Promise((resolve, reject) => {
      axios({
        url: `dental-laboratory/${dentalLabId}/work-type`,
        method: 'GET'
      })
        .then(res => {
          commit('SET_DENTAL_LAB_WORK_TYPES', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  addWorkType({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.post('dental-laboratory/work-type/', item)
        .then(res => {
          commit('ADD_WORK_TYPE', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  editWorkType({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`dental-laboratory/work-type/${item.id}/`, item)
        .then(res => {
          commit('EDIT_WORK_TYPE', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  removeWorkType({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`dental-laboratory/work-type/${id}/`)
        .then(res => {
          commit('REMOVE_WORK_TYPE', id)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  // Color palette
  getColorPalettePerPage({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'dental-laboratory/color-palette/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_COLOR_PALETTE_PER_PAGE', res.data.results)
          commit('SET_COLOR_PALETTE_PER_PAGE_COUNT', res.data.count)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  getDentalLabColorPalette({commit}, dentalLabId) {
    return new Promise((resolve, reject) => {
      axios({url: `dental-laboratory/${dentalLabId}/color-palette`, method: 'GET'})
        .then(res => {
          commit('SET_DENTAL_LAB_COLOR_PALETTE', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  addColorPalette({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.post('dental-laboratory/color-palette/', item)
        .then(res => {
          commit('ADD_COLOR_PALETTE', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  editColorPalette({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`dental-laboratory/color-palette/${item.id}/`, item)
        .then(res => {
          commit('EDIT_COLOR_PALETTE', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  removeColorPalette({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`dental-laboratory/color-palette/${id}/`)
        .then(res => {
          commit('REMOVE_COLOR_PALETTE', id)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
}
