export default {
  // Purchase order
  purchaseOrders: [],
  purchaseOrdersTotalCount: 0,
  // Implant system
  implantSystemsPerPage: [],
  implantSystemsPerPageCount: 0,
  dentalLabImplantSystems: [],
  // Implant work type
  implantWorkTypesPerPage: [],
  implantWorkTypesPerPageCount: 0,
  dentalLabImplantWorkTypes: [],
  // Material type
  materialTypesPerPage: [],
  materialTypesPerPageCount: 0,
  dentalLabMaterialTypes: [],
  // Work type
  workTypesPerPage: [],
  workTypesPerPageCount: 0,
  dentalLabWorkTypes: [],
  // Color palette
  colorPalettePerPage: [],
  colorPalettePerPageCount: 0,
  dentalLabColorPalette: [],
  // Purchase order statuses
  statuses: [
    {value: 'new', label: 'Новый'},
    {value: 'lab-delivery', label: 'Ожидает доставку в лабораторию'},
    {value: 'lab-delivered', label: 'Доставлен в лабораторию'},
    {value: 'registration', label: 'Регистрация'},
    {value: 'registered', label: 'Зарегистрирован'},
    {value: 'clinic-delivered', label: 'Доставлен в клинику'},
  ],
}
