export default {
  notifications: [],
  notificationsTotalCount: 0,
  notificationMethods: [],
  notificationStatus: [
    {
      value: 'active',
      text: 'Активна',
      bgColor: '#FFFC00FF',
      color: '#000000'
    },
    {
      value: 'completed',
      text: 'Выполнена',
      bgColor: '#28c76f',
      color: '#FFFFFFFF'
    },
    {
      value: 'disabled',
      text: 'Не активна',
      bgColor: '#FF0001FF',
      color: '#FFFFFFFF'
    },
  ],
  notificationType: [
    {
      value: 'one-time',
      text: 'Разовая',
      bgColor: '#00cfe8',
      color: '#FFFFFFFF'
    },
    {
      value: 'permanent',
      text: 'Постоянная',
      bgColor: '#7367f0',
      color: '#FFFFFFFF'
    },
  ],
  systemNotificationConditionCategory: [],
  notificationUsersPerPage: [],
  notificationUsersPerPageCount: 0,
  systemNotificationPatientFormTypes: [],
  onlineSystemNotifications: [],
}
