export default {
  // Patient notification
  SET_NOTIFICATIONS(state, items) {
    state.notifications = items
  },
  SET_NOTIFICATION_TOTAL_COUNT(state, count) {
    state.notificationsTotalCount = count
  },
  NOTIFICATION_ADDED(state, item) {
    state.notifications.push(item)
  },
  NOTIFICATION_EDITED(state, data) {
    const idx = state.notifications.findIndex(item => item.id === data.id)
    Object.keys(data).forEach(key => {
      state.notifications[idx][key] = data[key]
    })
  },
  NOTIFICATION_ENABLED(state, data) {
    const idx = state.notifications.findIndex(item => item.id === data.id)
    state.notifications[idx].status = 'active'
  },
  NOTIFICATION_DISABLED(state, data) {
    const idx = state.notifications.findIndex(item => item.id === data.id)
    state.notifications[idx].status = 'disabled'
  },
  NOTIFICATION_DELETED(state, id) {
    const idx = state.notifications.findIndex(p => p.id === id)
    state.notifications.splice(idx, 1)
  },
  SET_NOTIFICATION_METHODS(state, items) {
    state.notificationMethods = items
  },
  // System Notification Condition properties
  SET_SYSTEM_NOTIFICATION_CONDITION_CATEGORIES(state, items) {
    state.systemNotificationConditionCategory = items
  },
  // Notification user
  SET_NOTIFICATIONS_USERS_PER_PAGE(state, items) {
    state.notificationUsersPerPage = items
  },
  SET_NOTIFICATIONS_USERS_PER_PAGE_COUNT(state, count) {
    state.notificationUsersPerPageCount = count
  },
  // Notification patient form types
  SET_SYSTEM_NOTIFICATION_PATIENT_FORM_TYPES(state, items) {
    state.systemNotificationPatientFormTypes = items
  },
  // Online system notifications
  SET_ONLINE_SYSTEM_NOTIFICATIONS: (state, items) => state.onlineSystemNotifications = items
}
