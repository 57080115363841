import {entityNotification} from '@/libs/acl/entities'

export default [
  {
    path: '/apps/notifications',
    name: 'apps-notifications',
    component: () => import('@/views/notifications/Notifications.vue'),
    meta: {
      contentClass: 'notifications-application',
      resource: entityNotification,
      action: 'read',
      title: 'Sending out notifications'
    },
  }
]
