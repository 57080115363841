import axios from '@/libs/axios'

export default {
  // Wage by minute
  getWageByMinute({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'wage-by-minute/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_WAGE_BY_MINUTE', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  addWageByMinute({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `wage-by-minute/`,
        method: 'POST',
        data: params
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  editWageByMinute({commit}, item) {
    return new Promise((resolve, reject) => {
      axios({
        url: `wage-by-minute/${item.id}/`,
        method: 'PATCH',
        data: item
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  // Additional wage
  getAdditionalWage({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'additional-wage/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_ADDITIONAL_WAGE', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  addAdditionalWage({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `additional-wage/`,
        method: 'POST',
        data: params
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  editAdditionalWage({commit}, item) {
    return new Promise((resolve, reject) => {
      axios({
        url: `additional-wage/${item.id}/`,
        method: 'PATCH',
        data: item
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  // Service tariff
  changeServiceTariff({commit}, item) {
    return new Promise((resolve, reject) => {
      axios({
        url: `service/${item.id}/change-tariff`,
        method: 'PATCH',
        data: item.bodyParams
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  fetchServiceTariffHistory({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `service/${params.id}/tariff-history`,
        method: 'GET',
        params: params.queryParams
      })
        .then(res => {
          commit('SET_SERVICE_TARIFF_HISTORY', res.data.results)
          commit('SET_SERVICE_TARIFF_HISTORY_COUNT', res.data.count)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  initServiceCalculationExportProcess({ commit }) {
    return new Promise((resolve, reject) => {
      commit('SET_FILE_EXPORT_PROCESS_STATE', true)
      axios({url: `service-calculation-export/`, method: 'GET'})
        .then(res => resolve(res))
        .catch(e => {
          commit('SET_FILE_EXPORT_PROCESS_STATE', false)
          reject({message: e})
        })
    })
  },
  getServiceCalculationExportFile({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `service-calculation-export-file/`, method: 'GET', params: params, responseType: 'blob'
      })
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
        .finally(() => commit('SET_FILE_EXPORT_PROCESS_STATE', false))
    })
  },
}
