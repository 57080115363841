import { getId } from '@/composables/currentUser'


export const addDataToLocalStorageByPrefix = (prefix, data) => {
  const userId = getId()
  if (userId && data) {
    localStorage.setItem(`${userId}-${prefix}`, JSON.stringify(data))
  }
}

export const getDataFromLocalStorageByPrefix = prefix => {
  const userId = getId()
  if (userId) {
    let item = localStorage.getItem(`${userId}-${prefix}`)
    item = item ? JSON.parse(item) : null
    return item ? item : null
  }
  return null
}

export const removeDataFromLocalStorageByPrefix = prefix => {
  const userId = getId()
  if (userId) {
    localStorage.removeItem(`${userId}-${prefix}`)
  }
}
