import { addDataToLocalStorageByPrefix, getDataFromLocalStorageByPrefix } from '@/composables/localStorageHelper'

export const sortGroupsByPositions = (groups, localStorageKey) => {
  let groupsList = []
  if (groups.length) {
    const positions = getDataFromLocalStorageByPrefix(localStorageKey)
    const excludedIds = []
    if (positions) {
      Object.keys(positions).map(k => {
        if (!groups.find(g => g.id.toString() === k)) {
          excludedIds.push(k)
        }
      })
    }
    const result = []
    const newGroups = []
    groups.map(g => {
      g.children = []
      if (positions && g.id.toString() in positions) {
        const oldPosition = positions[g.id]
        let counter = 0
        if (excludedIds) {
          counter = excludedIds.reduce((v, i) =>  oldPosition > positions[i] ? v + 1 : v, 0)
        }
        result[oldPosition - counter] = g
      } else {
        newGroups.push(g)
      }
    })
    groupsList = result.concat(newGroups)

    const data = {}
    groupsList.map((g, i) => data[g.id] = i)
    addDataToLocalStorageByPrefix(localStorageKey, data)
  } else {
    groupsList = []
  }
  return groupsList
}
