<template>
  <div class="toastification">
    <div class="d-flex align-items-start">
      <b-avatar
        :variant="iconVariant"
        size="1.8rem"
        class="mr-75 flex-shrink-0"
      >
        <feather-icon
          icon="AlertTriangleIcon"
          size="15"
        ></feather-icon>
      </b-avatar>
      <div class="d-flex flex-grow-1">
        <div class="w-100">
          <h5
            class="mb-0 font-weight-bolder toastification-title"
            :class="{
              'text-success': action === 'confirm',
              'text-warning': action === 'move',
              'text-danger': action === 'cancel',
              'text-info': action === 'feedback'
            }"
          >Внимание!</h5>
          <h5
            v-if="title"
            class="mt-1 mb-1 text-body font-weight-bold"
          >{{ title }}</h5>
          <hr>
          <div
            v-if="patientFullName"
            class="d-flex align-items-center"
          >
            <span class="text-body mr-50 font-weight-bold">ФИО:</span>
            <small class="d-inline-block text-body">{{ patientFullName }}</small>
          </div>
          <div
            v-if="formattedPhone"
            class="d-flex align-items-center"
          >
            <span class="text-body mr-50 font-weight-bold">Телефон:</span>
            <small class="d-inline-block text-body">{{ formattedPhone }}</small>
          </div>
          <div
            v-if="appointmentDate"
            class="d-flex align-items-center"
          >
            <span class="text-body mr-50 font-weight-bold">Дата приема:</span>
            <small class="d-inline-block text-body">{{ appointmentDate }}</small>
          </div>
          <div
            v-if="note"
            class="d-flex align-items-center"
          >
            <span class="text-body mr-50 font-weight-bold">
              {{ action === 'feedback' ? 'Отзыв' : 'Комментарий' }}:
            </span>
            <small class="d-inline-block text-body">{{ note }}</small>
          </div>
          <div
            v-if="action === 'feedback'"
            class="d-flex align-items-center"
          >
            <span class="text-body mr-50 font-weight-bold">Оценка:</span>
            <b-avatar
              :variant="`light-${rate >= 3 ? (rate > 3 ? 'success' : 'warning') : 'danger'}`"
              size="25"
            >{{ rate }}</b-avatar>
          </div>
          <div
            class="mt-1"
          >
            <b-link :to="routerLinkParamsForShowPatientHistoryRequest">
              <small class="d-inline-block">Подробнее</small>
            </b-link>
          </div>
        </div>
        <span
          class="cursor-pointer toastification-close-icon ml-auto"
          @click="$emit('close-toast')"
        >
          <feather-icon
            icon="XIcon"
            class="text-body ml-1"
          ></feather-icon>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar, BBadge, BLink } from 'bootstrap-vue'
import { computed } from 'vue'
import { mixins } from '@/mixins'

export default {
  name: 'ToastificationPatientProcessedAppointmentNotification',
  components: {
    BBadge, BAvatar, BLink
  },
  props: {
    appointmentId: Number,
    title: String,
    patientId: Number,
    patientFullName: String,
    phoneCode: String,
    phone: String,
    note: String,
    rate: Number,
    appointmentDate: String,
    action: String
  },
  setup(props) {
    const serviceName = `Поступил новый лид`
    const formattedPhone = computed(() => {
      if (props.phoneCode && props.phone) {
        const number = props.phone
        let result = props.phone
        if (number.length === 10) {
          result = '(' + number.substring(0, 3) + ') ' + number.substring(3, 6) + '-' + number.substring(6, 8)
            + '-' + number.substring(8, 10)
        } else if (number.length === 9) {
          result = '(' + number.substring(0, 2) + ') ' + number.substring(2, 5) + '-' + number.substring(5, 7)
            + '-' + number.substring(7, 9)
        }
        return '+' + props.phoneCode + ' ' + result
      }
      return null
    })
    const iconVariant = computed(() => {
      let variant = 'success'
      if (props.action === 'move') {
        variant = 'warning'
      } else if (props.action === 'cancel') {
        variant = 'danger'
      } else if (props.action === 'feedback') {
        variant = 'info'
      }
      return variant
    })
    const routerLinkParamsForShowPatientHistoryRequest = computed(() => {
      let activeTab = mixins.methods.userHasProfmanDentalProgramVersion() ? 2 : 3
      activeTab = mixins.methods.tabDentistryIsHidden() ? activeTab - 1 : activeTab
      return {
        path: `/apps/patient_cards/patient-edit/${props.patientId}`,
        params: {
          patientId: props.patientId,
          activeTabToShow: activeTab,
          routerBreadcrumbParams: {sourceItemLabel: props.patientFullName}
        },
        query: {
          sourceItemLabel: props.patientFullName,
          activeTabToShow: activeTab
        }
      }
    })

    return {
      formattedPhone,
      serviceName,
      iconVariant,
      routerLinkParamsForShowPatientHistoryRequest
    }
  }
}
</script>
