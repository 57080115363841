import {entityNotification} from '@/libs/acl/entities'

export default [
  {
    title: 'Sending out notifications',
    icon: 'BellIcon',
    resource: entityNotification,
    action: 'read',
    route: 'apps-notifications',
  }
]
