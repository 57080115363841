import axios from '@/libs/axios'

export default {
  // Notification
  getNotificationsPerPage({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'notification/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_NOTIFICATIONS', res.data.results)
          commit('SET_NOTIFICATION_TOTAL_COUNT', res.data.count)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  addNotification({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'notification/',
        method: 'POST',
        data: data,
        headers: {'Content-Type': 'multipart/form-data'},
      })
        .then(res => {
          commit('NOTIFICATION_ADDED', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  editNotification({commit}, item) {
    return new Promise((resolve, reject) => {
      axios({
        url: `notification/${item.get('id')}/`,
        method: 'PATCH',
        data: item,
        headers: {'Content-Type': 'multipart/form-data'},
      })
        .then(res => {
          commit('NOTIFICATION_EDITED', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  onNotification({commit}, item) {
    return new Promise((resolve, reject) => {
      axios({
        url: `notification/${item.id}/on`,
        method: 'PATCH',
      })
        .then(res => {
          commit('NOTIFICATION_ENABLED', item)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  offNotification({commit}, item) {
    return new Promise((resolve, reject) => {
      axios({
        url: `notification/${item.id}/off`,
        method: 'PATCH',
      })
        .then(res => {
          commit('NOTIFICATION_DISABLED', item)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  confirmSeenSubscriptionExpiredNotification({commit}, userId) {
    return new Promise((resolve, reject) => {
      axios({url: `user/${userId}/subscription-expired-notification-seen`, method: 'PATCH'})
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  confirmSeenMinimumSmsNotificationBalanceNotification({commit}, userId) {
    return new Promise((resolve, reject) => {
      axios({url: `user/${userId}/minimum-sms-notification-balance-notification-seen`, method: 'PATCH'})
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  confirmSeenNotification({commit}, itemId) {
    return new Promise((resolve, reject) => {
      axios({
        url: `notification/${itemId}/seen`,
        method: 'PATCH',
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  deleteNotification({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `notification/${id}/`,
        method: 'DELETE'
      })
        .then(res => {
          commit('NOTIFICATION_DELETED', id)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  getNotificationMethods({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'notification-method/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_NOTIFICATION_METHODS', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  // System Notification Condition properties
  getSystemNotificationConditionCategory({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'system-notification-condition-category/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_SYSTEM_NOTIFICATION_CONDITION_CATEGORIES', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  getSystemNotificationConditionCategoryAttribute({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'system-notification-condition-category-attribute/',
        method: 'GET',
        params: params.queryParams
      })
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  getSystemNotificationConditionComparisonOperator({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'system-notification-condition-comparison-operator/',
        method: 'GET',
        params: params.queryParams
      })
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  // Patients
  getNotificationPatientsByConditions({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'notification-patient-by-condition/',
        method: 'GET',
        params: params.queryParams
      })
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  // Notification user
  getNotificationUsersPerPage({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `notification/${params.id}/user`,
        method: 'GET',
        params: params.queryParams
      })
        .then(res => {
          commit('SET_NOTIFICATIONS_USERS_PER_PAGE', res.data.results)
          commit('SET_NOTIFICATIONS_USERS_PER_PAGE_COUNT', res.data.count)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  // Notification patient form types
  getSystemNotificationPatientFormType({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'system-notification-patient-form-type/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_SYSTEM_NOTIFICATION_PATIENT_FORM_TYPES', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  // Online system notifications
  getOnlineSystemNotifications({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: 'online-system-notification/', method: 'GET', params: params})
        .then(res => {
          commit('SET_ONLINE_SYSTEM_NOTIFICATIONS', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  }
}
