import moment from 'moment'
import { extendMoment } from 'moment-range'
const momentRange = extendMoment(moment)

export default {
  getDesignations: state => state.designations.filter(d => d.is_count === false),
  getAllDesignations: state => state.designations,
  getCabinetDesignations: state => state.designations.filter(d => d.is_count === true),
  getSchedulePatientList: state => state.schedulePatientList,
  getSchedulePatient: state => state.schedulePatient,
  getSchedulePatientById: state => id => {
    return state.schedulePatientList.find(s => s.id === id) || null
  },
  getSelectedCalendars: state => state.selectedCalendars,
  getCalendarOptions: state => state.calendarOptions,
  getCalendarOption: state => docId => {
    return state.calendarOptions.find(op => op.id === docId) || null
  },
  getSchedulePatientStatuses: state => state.schedulePatientStatuses,
  getSchedulePatientStatus: state => key => {
    return state.schedulePatientStatuses.find(s => s.key === key) || null
  },
  getDesignation: state => state.designation,
  getEmployeeSchedule: state => state.employeeSchedule,
  getShifts: state => state.shifts,
  getShift: state => state.shift,
  schedulerDoctorsGroups: state => state.schedulerDoctorsGroups,
  doctorShiftsForAppointmentCalendar: state => state.doctorShiftsForAppointmentCalendar,
  doctorWeekendDates: state => state.doctorWeekendDates,
  calendarView: state => state.scheduleCalendarView,
  backupVisits: state => state.backupVisits,
  backupVisitsCount: state => state.backupVisitsCount,
  loadingDoctors: state => state.loadingDoctors,
  roleList: state => state.roles,
  getActiveRange: state => state.activeRange,
  listDayExtendedCalendarDateState: state => state.listDayExtendedCalendarDateState,
  findReserve: state => id => {
    return state.reserves.find(i => i.id === id) || null
  },
  reserves: state => state.reserves,
  findSchedulePatientByCabinets: state => (ids, start, finish) => {
    const range = momentRange.range(moment(start), moment(finish))
    return state.schedulePatientList.filter(patientSchedule => {
      const rangeEvent = momentRange.range(
        moment(parseInt(patientSchedule.start_time)),
        moment(parseInt(patientSchedule.finish_time))
      )
      return ids.find(id => patientSchedule.cabinet && patientSchedule.cabinet.id === id)
        && rangeEvent.overlaps(range)
    })
  },
  findCabinetsByDoctor: state => docId => {
    return state.cabinetsForUser.filter(p => p.user.id ===  docId)
  },
  findCabinetByDoctor: state => docId => {
    let find = state.schedulePatientList.find(p => p.doctor.id ===  docId)
    return find && find.cabinet ? find.cabinet : null
  },
  selectedDoctorId: state => state.selectedDoctorId,
  getSelectedDoctors: state => state.selectedDoctors,
  getActiveTab: state => state.activeTab,
  getDoctors: state => state.doctors,
  getNewPatientAppointmentInfo: state => state.newPatientAppointmentInfo,
  newPatientAppointmentInfoWatcher: state => state.newPatientAppointmentInfoWatcher,
  copiedEmployeeScheduleStatusInCalendar: state => state.copiedEmployeeScheduleStatusInCalendar,
  selectedDaysInEmployeeScheduleCalendar: state => state.selectedDaysInEmployeeScheduleCalendar,
  // Scheduler patient notification timeouts
  schedulerPatientNotificationTimeouts: s => s.schedulerPatientNotificationTimeouts
}
