import axios from '@/libs/axios'

export default {
  // Chat message
  sendMessage({commit}, data) {
    return new Promise((resolve, reject) => {
      axios.post(`${data.url}/`, data.item)
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  sendMessageToPatientChat({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient-chat-message/`,
        method: 'POST',
        headers: {'Content-Type': 'multipart/form-data'},
        data: data
      })
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  editMessage({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `user-chat-message/${data.id}/`,
        method: 'PATCH',
        data: data
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  deleteMessage({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`user-chat-message/${id}/`)
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  // Users chats
  getUsersChats({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'user-chat/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_USERS_CHATS', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  getActiveChatInfo({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `user-chat/${params.id}/`,
        method: 'GET',
        params: params.queryParams
      })
        .then(res => {
          commit('SET_ACTIVE_CHAT_INFO', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  seenChatMessages({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: data.url,
        method: 'PATCH',
        data: data.bodyParams
      })
        .then(res => {
          commit(data.mutation, data.chat_id)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  // Patients chats
  getPatientsChats({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'patient-chat/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_PATIENTS_CHATS', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  getPatientActiveChatInfo({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient-chat/${params.id}/`,
        method: 'GET',
        params: params.queryParams
      })
        .then(res => {
          commit('SET_ACTIVE_CHAT_INFO', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  // Contacts
  getContacts({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'chat-contact/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_CONTACTS', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  // Messengers
  setActiveMessenger({commit}, type) {
    commit('SET_ACTIVE_MESSENGER', type)
  }
}
