import {entityTask} from "@/libs/acl/entities";

export default [
  {
    title: 'Todo',
    icon: 'CheckSquareIcon',
    resource: entityTask,
    action: 'read',
    route: 'apps-todo-my',
  },
]
