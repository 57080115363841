import moment from "moment";

const currentTaskHasSuitableCategoryType = (state, task, currentUser) => {
  let canAddTaskToCurrentList = false
  if (!state.categoryTypeFilter) {
    canAddTaskToCurrentList = true
  } else if (
    state.categoryTypeFilter === 'assigned'
    && task.user.filter(user => user.id === currentUser.id).length === 0
    && task.creator.id === currentUser.id
  ) {
    canAddTaskToCurrentList = true
  } else if (
    state.categoryTypeFilter === 'my'
    && task.user.filter(user => user.id === currentUser.id).length > 0
  ) {
    canAddTaskToCurrentList = true
  }
  return canAddTaskToCurrentList
}
const currentHasSuitableDueDateCategory = (state, task) => {
  if (!state.dueDateCategoryFilterActive) {
    return true
  } else {
    const taskSeconds = moment(task.due_date, "YYYY-MM-DD").valueOf() / 1000
    const currentSeconds = moment().unix()
    return taskSeconds < currentSeconds
  }
}
const currentHasSuitableDueDate = (state, task) => {
  if (!state.filterDueDate) {
    return true
  } else {
    return moment(parseInt(task.due_date)).format('YYYY-MM-DD') === state.filterDueDate
  }
}
const currentHasSuitableTag = (state, task) => {
  if (!state.filterTagId) {
    return true
  } else if (task.tag) {
    let tagIsSuitable = false
    task.tag.forEach(tag => {
      if (tag.id === state.filterTagId) {
        tagIsSuitable = true
      }
    })
    return tagIsSuitable
  }
}
const currentHasSuitableStatus = (state, task) => {
  if (!state.filterStatusId) {
    return true
  } else if (task.status) {
    return task.status.id === state.filterStatusId
  }
}
const currentHasSuitableEmployee = (state, task) => {
  if (!state.employeeFilter || !state.employeeFilter.length) {
    return true
  } else {
    let employeeExistInFilter = false
    state.employeeFilter.forEach(employee => {
      if (
        !employeeExistInFilter
        && (task.user.filter(user => user.id === employee.id).length > 0 || employee.id === task.creator.id)
      ) {
        employeeExistInFilter = true
      }
    })
    return employeeExistInFilter
  }
}
const currentHasSuitablePriority = (state, task) => {
  if (!state.filterPriorityId) {
    return true
  } else if (task.priority) {
    return task.priority.id === state.filterPriorityId
  }
}
const canAddTaskToCurrentList = (state, task) => {
  const currentUser = JSON.parse(localStorage.getItem('user'))

  if (!currentTaskHasSuitableCategoryType(state, task, currentUser)) {
    return false
  }

  if (!currentHasSuitableDueDateCategory(state, task)) {
    return false
  }

  if (!currentHasSuitableTag(state, task)) {
    return false
  }

  if (!currentHasSuitableStatus(state, task)) {
    return false
  }

  if (!currentHasSuitableEmployee(state, task)) {
    return false
  }

  if (!currentHasSuitablePriority(state, task)) {
    return false
  }

  return currentHasSuitableDueDate(state, task)
}
export default {
  // Tasks
  SET_TASKS (state, tasks) {
    state.tasks = tasks
  },
  ADD_TASK (state, task) {
    if (canAddTaskToCurrentList(state, task)) {
      state.tasks.push(task)
    }
  },
  DELETE_TASK (state, taskId) {
    const index = state.tasks.findIndex(task => task.id === taskId)
    state.tasks.splice(index, 1)
  },
  EDIT_TASK (state, task) {
    const currentUser = JSON.parse(localStorage.getItem('user'))
    const index = state.tasks.findIndex(item => item.id === task.id)
    if (index !== -1) {
      state.tasks.splice(index, 1)
    }
    if (canAddTaskToCurrentList(state, task)) {
      state.tasks.push(task)
    }
    state.task = task
  },
  // Tasks tags
  SET_TAGS (state, tags) {
    state.tags = tags
  },
  ADD_TAG (state, tag) {
    state.tags.push(tag)
  },
  DELETE_TAG (state, tagId) {
    const index = state.tags.findIndex(tag => tag.id === tagId)
    state.tags.splice(index, 1)
  },
  // Tasks statuses
  SET_STATUSES (state, statuses) {
    state.statuses = statuses
  },
  ADD_STATUS (state, status) {
    state.statuses.push(status)
  },
  DELETE_STATUS (state, statusId) {
    const index = state.statuses.findIndex(status => status.id === statusId)
    state.statuses.splice(index, 1)
  },
  // Tasks priorities
  SET_PRIORITIES (state, priorities) {
    state.priorities = priorities
  },
  ADD_PRIORITY (state, priority) {
    state.priorities.push(priority)
  },
  DELETE_PRIORITY (state, priorityId) {
    const index = state.priorities.findIndex(priority => priority.id === priorityId)
    state.priorities.splice(index, 1)
  },
  // Tasks filter
  CHANGE_TAG_FILTER (state, filterData) {
    state.filterTagId = filterData.active ? filterData.id : null
  },
  CHANGE_STATUS_FILTER (state, filterData) {
    state.filterStatusId = filterData.active ? filterData.id : null
  },
  CHANGE_DUE_DATE_CATEGORY_FILTER (state, filterData) {
    state.dueDateCategoryFilterActive = filterData.enabled
  },
  CHANGE_DUE_DATE_FILTER (state, filterData) {
    state.filterDueDate = filterData.due_date
  },
  CHANGE_CATEGORY_TYPE_FILTER (state, filterData) {
    state.categoryTypeFilter = filterData.active ? filterData.type : null
  },
  CHANGE_EMPLOYEE_FILTER (state, filterData) {
    state.employeeFilter = filterData.employees
  },
  CHANGE_PRIORITY_FILTER (state, filterData) {
    state.filterPriorityId = filterData.active ? filterData.id : null
  },
}
