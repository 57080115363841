export default {
  // Wage
  SET_WAGE_DOCTORS(state, data) {
    state.wageDoctors = data
  },
  // Doctors
  SET_DOCTORS(state, data) {
    state.doctors = data
  },
}
