import {entityFaq} from '@/libs/acl/entities'

export default [
  {
    path: '/apps/faq',
    name: 'apps-faq',
    component: () => import('@/views/faq/Faq.vue'),
    meta: {
      contentClass: 'faq-application',
      resource: entityFaq,
      action: 'read'
    },
  }
]
