import Vue from 'vue'

export default {
  // Lead history
  SET_LEAD_HISTORY_PER_PAGE(state, items) {
    state.leadHistoryPerPage = items
  },
  SET_LEAD_HISTORY_TOTAL_COUNT(state, count) {
    state.leadHistoryTotalCount = count
  },
  SET_LEAD_HISTORY_BY_LEAD_ID_PER_PAGE(state, items) {
    state.leadHistoryByLeadIdPerPage = items
  },
  SET_LEAD_HISTORY_BY_LEAD_ID_TOTAL_COUNT(state, count) {
    state.leadHistoryByLeadIdTotalCount = count
  },
  ADD_LEAD_HISTORY_ITEM(state, item) {
    state.leadHistoryByLeadIdPerPage.unshift(item)
    state.leadHistoryByLeadIdTotalCount += 1
  },
  EDIT_LEAD_HISTORY_ITEM(state, item) {
    let index = state.leadHistoryByLeadIdPerPage.findIndex(element => element.id === item.id)
    Vue.set(state.leadHistoryByLeadIdPerPage, index, item)
  },
  DELETE_LEAD_HISTORY_ITEM(state, id) {
    let index = state.leadHistoryByLeadIdPerPage.findIndex(ad => ad.id === id)
    state.leadHistoryByLeadIdPerPage.splice(index, 1)
    state.leadHistoryByLeadIdTotalCount -= 1
    index = state.leadHistoryPerPage.findIndex(ad => ad.id === id)
    state.leadHistoryPerPage.splice(index, 1)
    state.leadHistoryTotalCount -= 1
  },
  // Lead history statuses
  SET_LEAD_HISTORY_STATUS(state, items) {
    state.leadHistoryStatus = items
  },
  // UTM-sources
  SET_UTM_SOURCES_PER_PAGE: (state, items) => state.utmSourcesPerPage = items,
  SET_UTM_SOURCES_PER_PAGE_COUNT: (state, count) => state.utmSourcesPerPageCount = count,
  ADD_UTM_SOURCE(state, item) {
    state.utmSourcesPerPage.push(item)
    state.utmSourcesPerPageCount += 1
  },
  EDIT_UTM_SOURCE(state, item) {
    const index = state.utmSourcesPerPage.findIndex(us => us.id === item.id)
    Vue.set(state.utmSourcesPerPage, index, item)
  },
  REMOVE_UTM_SOURCE(state, itemId) {
    const index = state.utmSourcesPerPage.findIndex(us => us.id === itemId)
    state.utmSourcesPerPage.splice(index, 1)
    state.utmSourcesPerPageCount -= 1
  },
  // UTM-mediums
  SET_UTM_MEDIUMS_PER_PAGE: (state, items) => state.utmMediumsPerPage = items,
  SET_UTM_MEDIUMS_PER_PAGE_COUNT: (state, count) => state.utmMediumsPerPageCount = count,
  ADD_UTM_MEDIUM(state, item) {
    state.utmMediumsPerPage.push(item)
    state.utmMediumsPerPageCount += 1
  },
  EDIT_UTM_MEDIUM(state, item) {
    const index = state.utmMediumsPerPage.findIndex(us => us.id === item.id)
    Vue.set(state.utmMediumsPerPage, index, item)
  },
  REMOVE_UTM_MEDIUM(state, itemId) {
    const index = state.utmMediumsPerPage.findIndex(us => us.id === itemId)
    state.utmMediumsPerPage.splice(index, 1)
    state.utmMediumsPerPageCount -= 1
  },
}
