export default {
  // Reports
  SET_REPORT_FORM_039(state, reports) {
    state.reportForm039 = reports
  },
  SET_REPORT_FORM_039_3(state, reports) {
    state.reportForm039_3 = reports
  },
  SET_REPORT_FORM_037(state, reports) {
    state.reportForm037 = reports
  },
  // Outpatient Surgeries
  SET_OUTPATIENT_SURGERIES(state, items) {
    state.outpatientSurgeries = items
  },
  // Journal of paid services
  SET_JOURNAL_OF_PAID_SERVICES(state, items) {
    state.paidServicesJournal = items
  },
  SET_JOURNAL_OF_PAID_SERVICES_COUNT(state, count) {
    state.paidServicesJournalCount = count
  },
  SET_JOURNAL_OF_PAID_SERVICES_TOTAL(state, items) {
    state.paidServicesJournalTotal = items
  },
  SET_CERTIFICATES_WORK_PERFORMED(state, items) {
    state.certificatesWorkPerformed = items
  },
  SET_PAID_MEDICAL_SERVICES_INFORMATION(state, items) {
    state.paidMedicalServicesInformation = items
  },
}
