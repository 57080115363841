import {
  entityPatientOutpatientSurgery, entityPatient, entityMaterialLot, entityPatientRecommendedVisit, entityPurchaseOrder
} from '@/libs/acl/entities'

export default [
  {
    header: 'Reports',
    icon: 'GridIcon',
    action: 'read',
    route: 'reports',
    children: [
      {
        route: 'outpatient-surgeries',
        title: 'Outpatient surgeries',
        resource: entityPatientOutpatientSurgery,
        action: 'read'
      },
      {
        route: 'work-performed-dl',
        title: 'Work performed (DL)',
        resource: entityPurchaseOrder,
        action: 'read'
      },
      {
        route: 'journal-of-paid-services',
        title: 'Journal of paid services',
        resource: entityPatient,
        action: 'read'
      },
      {
        route: 'paid-medical-services-information',
        title: 'Information about scope of paid medical services',
        resource: entityPatient,
        action: 'read'
      },
      {
        route: 'report-waybill-materials',
        title: 'List of materials in bill of lading',
        resource: entityMaterialLot,
        action: 'read'
      },
      {
        route: 'certificates-work-performed',
        title: 'Register of certificates of work performed',
        resource: entityPatient,
        action: 'read'
      },
      {
        route: 'report-recommended-visits',
        title: 'Recommended visits',
        resource: entityPatientRecommendedVisit,
        action: 'read'
      },
      {
        route: 'report-form-037',
        title: 'ReportForm037',
        resource: entityPatient,
        action: 'read'
      },
      {
        route: 'report-form-039',
        title: 'ReportForm039',
        resource: entityPatient,
        action: 'read'
      },
      {
        route: 'report-form-039-3',
        title: 'ReportForm039_3',
        resource: entityPatient,
        action: 'read'
      }
    ]
  }
]
