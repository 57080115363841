import appConstants from '@/constants/constants'
import router from '@/router'
import store from '@/store'
import { getUserData } from '@/auth/utils'
import { playNewMessageSound } from '@/composables/audioHelper'
import { showNewMessageToast, showTelegramToast, showViberToast } from '@/composables/toast'

export const processNewChatMessageByRoute = data => {
  if (
    [
      appConstants.router.chat.APP_USER_CHAT, appConstants.router.chat.APP_PATIENT_CHAT
    ].includes(router.currentRoute.name)
  ) {
    store.commit('chat/SET_NEW_MESSAGE', data)
  } else {
    processNewChatMessage(data)
  }
}

export const processNewChatMessage = data => {
  const m = data.message
  if (m.type === 'Viber') {
    showViberToast(m.sender_full_name, m.text)
  } else if (m.type === 'Telegram') {
    showTelegramToast(m.sender_full_name, m.text)
  } else {
    showNewMessageToast(m.sender_full_name, m.text)
  }
  playNewMessageSound()
}

export const viberApiEnabled = () => {
  const userData = getUserData()
  return userData && userData.viber_api_enabled
}

export const telegramApiEnabled = () => {
  const userData = getUserData()
  return userData && userData.telegram_api_enabled
}

export const enabledChatMessengers = () => {
  const types = []
  if (viberApiEnabled()) {
    types.push(appConstants.chat.messengerType.VIBER)
  }
  if (telegramApiEnabled()) {
    types.push(appConstants.chat.messengerType.TELEGRAM)
  }
  return types
}

export const getTelegramIcon = () => {
  return store.state.appConfig.layout.skin === 'dark'
    ? require(`@/assets/images/contacts/telegram-dark-theme.svg`)
    : require(`@/assets/images/contacts/telegram-white.svg`)
}

export const getViberIcon = () => {
  return store.state.appConfig.layout.skin === 'dark'
    ? require(`@/assets/images/contacts/viber-dark-theme.svg`)
    : require(`@/assets/images/contacts/viber-white.svg`)
}

