import { entityPurchaseOrder } from '@/libs/acl/entities'

export default [
  {
    header: 'Dental laboratory',
    icon: 'PackageIcon',
    action: 'read',
    route: 'dental-laboratory',
    children: [
      {
        title: 'Purchase order',
        route: 'apps-purchase-order',
        resource: entityPurchaseOrder,
        action: 'read'
      },
    ],
  },
]
