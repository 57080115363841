import axios from '@/libs/axios'

export default {
  // Wage
  getDoctorsWage({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'wage/doctors/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_WAGE_DOCTORS', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  // Doctors
  getDoctors({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'wage/doctor-list/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_DOCTORS', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
}
