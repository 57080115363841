export default {
  // Wage by minute
  wageByMinute: s => s.wageByMinute,
  // Additional wage
  additionalWage: s => s.additionalWage,
  // Service tariff history
  serviceTariffHistory: s => s.serviceTariffHistory,
  serviceTariffHistoryCount: s => s.serviceTariffHistoryCount,
  // Service and wage calculation
  exportProcessActive: s => s.exportProcessActive
}
