import {
  entityPatientOutpatientSurgery, entityPatient, entityMaterialLot, entityPatientRecommendedVisit, entityPurchaseOrder
} from '@/libs/acl/entities'

export default [
  {
    path: '/reports/form-039',
    name: 'report-form-039',
    component: () => import('@/views/reports/ReportForm039'),
    meta: {
      resource: entityPatient,
      action: 'read',
      title: 'ReportForm039'
    }
  },
  {
    path: '/reports/form-039-3',
    name: 'report-form-039-3',
    component: () => import('@/views/reports/ReportForm039_3'),
    meta: {
      resource: entityPatient,
      action: 'read',
      title: 'ReportForm039_3'
    }
  },
  {
    path: '/reports/form-037',
    name: 'report-form-037',
    component: () => import('@/views/reports/ReportForm037'),
    meta: {
      resource: entityPatient,
      action: 'read',
      title: 'ReportForm037'
    }
  },
  {
    path: '/reports/outpatient-surgeries',
    name: 'outpatient-surgeries',
    component: () => import('@/views/reports/OutpatientSurgeries'),
    meta: {
      resource: entityPatientOutpatientSurgery,
      action: 'read',
      title: 'Outpatient surgeries'
    }
  },
  {
    path: '/reports/journal-of-paid-services',
    name: 'journal-of-paid-services',
    component: () => import('@/views/reports/JournalOfPaidServices'),
    meta: {
      resource: entityPatient,
      action: 'read',
      title: 'Journal of paid services'
    }
  },
  {
    path: '/reports/certificates-work-performed',
    name: 'certificates-work-performed',
    component: () => import('@/views/reports/CertificatesWorkPerformed'),
    meta: {
      resource: entityPatient,
      action: 'read',
      title: 'Register of certificates of work performed'
    }
  },
  {
    path: '/reports/paid-medical-services-information',
    name: 'paid-medical-services-information',
    component: () => import('@/views/reports/PaidMedicalServicesInformation'),
    meta: {
      resource: entityPatient,
      action: 'read',
      title: 'Information about scope of paid medical services'
    }
  },
  {
    path: '/reports/employee',
    name: 'report-employee',
    component: () => import('@/views/reports/ReportEmployee')
  },
  {
    path: '/reports/financial',
    name: 'report-financial',
    component: () => import('@/views/reports/ReportFinancial')
  },
  {
    path: '/reports/metrics',
    name: 'report-metrics',
    component: () => import('@/views/reports/ReportMetrics')
  },
  {
    path: '/reports/dynamics',
    name: 'report-dynamics',
    component: () => import('@/views/reports/ReportDynamics')
  },
  {
    path: '/reports/waybill-materials',
    name: 'report-waybill-materials',
    component: () => import('@/views/reports/WaybillMaterials'),
    meta: {
      resource: entityMaterialLot,
      action: 'read',
      title: 'List of materials in bill of lading'
    }
  },
  {
    path: '/reports/recommended-visits',
    name: 'report-recommended-visits',
    component: () => import('@/views/reports/RecommendedVisits'),
    meta: {
      resource: entityPatientRecommendedVisit,
      action: 'read',
      title: 'Recommended visits'
    }
  },
  {
    path: '/reports/work-performed-dl',
    name: 'work-performed-dl',
    component: () => import('@/views/reports/WorkPerformedDL'),
    meta: {
      resource: entityPurchaseOrder,
      action: 'read',
      title: 'Work performed (DL)'
    }
  }
]
