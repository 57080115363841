import {entityPatientPayment} from '@/libs/acl/entities'

export default [
  {
    title: 'Wage calculation',
    icon: 'ClipboardIcon',
    resource: entityPatientPayment,
    action: 'read',
    route: 'apps-wage',
  }
]
