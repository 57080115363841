import {
  schedulerpatient, dictionarydesignationsscheduler, schedulercalendar, scheduler, workshift, entityEmployeeWorkDay,
} from '@/libs/acl/entities'

export default [
  {
    path: '/schedule/cabinets',
    name: 'schedule-cabinets',
    component: () => import('@/views/schedule/Cabinets.vue'),
    meta: {
      resource: dictionarydesignationsscheduler,
      action: 'read',
      title: 'Cabinet'
    }
  },
  {
    path: '/schedule/designations',
    name: 'schedule-designations',
    component: () => import('@/views/schedule/Designations.vue'),
    meta: {
      resource: dictionarydesignationsscheduler,
      action: 'read',
      title: 'Designations'
    }
  },
  {
    path: '/schedule/calendar',
    name: 'schedule-calendar',
    component: () => import('@/views/calendar/Calendar.vue'),
    meta: {
      resource: schedulerpatient,
      action: 'read',
      title: 'Schedule of reception'
    }
  },
  {
    path: '/schedule/employee-schedule',
    name: 'employee-schedule',
    component: () => import('@/views/schedule/employee-schedule/EmployeeSchedule.vue'),
    meta: {
      resource: [scheduler, schedulercalendar],
      action: 'read',
      title: 'Employee schedule'
    }
  },
  {
    path: '/schedule/shifts',
    name: 'shifts',
    component: () => import('@/views/schedule/Shifts.vue'),
    meta: {
      resource: workshift,
      action: 'read',
      title: 'Shifts'
    }
  },
  {
    path: '/schedule/time-tracking',
    name: 'time-tracking',
    component: () => import('@/views/schedule/TimeTracking.vue'),
    meta: {
      resource: entityEmployeeWorkDay,
      action: 'read',
      title: 'Time tracking'
    }
  },
]
