import state from './moduleDentalLaboratoryState'
import mutations from './moduleDentalLaboratoryMutations'
import actions from './moduleDentalLaboratoryActions'
import getters from './moduleDentalLaboratoryGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
