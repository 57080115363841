export default {
  auth_request(state){
    state.status = 'loading'
  },
  auth_success(state, token){
    state.status = 'success'
    state.token = token
  },
  auth_error(state){
    state.status = 'error'
  },
  logout(state){
    state.status = ''
    state.token = ''
  },
  set_user(state, user) {
    state.user.id = user.id
    state.user.first_name = user.first_name
    state.user.last_name = user.last_name
    state.user.middle_name = user.middle_name
    state.user.birth_date = user.birth_date
    state.user.isRoot = user.isRoot
  },
  SET_USER_SOCKET_CONNECTION(state, userSocketConnection) {
    state.userSocketConnection = userSocketConnection
  },
  CLEAR_USER_SOCKET_CONNECTION(state) {
    if (state.userSocketConnection) {
      state.userSocketConnection.close()
      state.userSocketConnection = null
    }
  },
  SET_INTEGRATOR_SOCKET_CONNECTION(state, integratorSocketConnection) {
    state.integratorSocketConnection = integratorSocketConnection
  },
  CLEAR_INTEGRATOR_SOCKET_CONNECTION(state) {
    if (state.integratorSocketConnection) {
      state.integratorSocketConnection.close()
      state.integratorSocketConnection = null
    }
  },
}
