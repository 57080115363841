import {entityLead} from '@/libs/acl/entities'

export default [
  {
    title: 'Leads',
    icon: 'BookOpenIcon',
    resource: entityLead,
    action: 'read',
    route: 'apps-leads',
  }
]
