import state from './moduleScheduleState.js'
import mutations from './moduleScheduleMutations.js'
import actions from './moduleScheduleActions.js'
import getters from './moduleScheduleGetters.js'

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
