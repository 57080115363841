import { entityPatient } from '@/libs/acl/entities'

export default [
  {
    route: 'patient-cards',
    title: 'Patients',
    icon: 'UsersIcon',
    resource: entityPatient,
    action: 'read'
  }
]
