import state from './moduleTodoState.js'
import mutations from './moduleTodoMutations.js'
import actions from './moduleTodoActions.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
