import {
  entityDictionaryproducts, entityDictionarysuppliers, entityHistoryOrders, entityOrderBucket
} from '@/libs/acl/entities'

export default [
  {
    header: 'Requests',
    icon: 'PackageIcon',
    action: 'read',
    route: 'requests',
    children: [
      {
        title: 'Suppliers',
        route: 'application-suppliers',
        resource: entityDictionarysuppliers,
        action: 'read'
      },
      {
        title: 'Products',
        route: 'application-products',
        resource: entityDictionaryproducts,
        action: 'read'
      },
      {
        title: 'Cart',
        route: 'application-bucket',
        resource: entityOrderBucket,
        action: 'read'
      },
      {
        title: 'Orders',
        route: 'application-orders',
        resource: entityHistoryOrders,
        action: 'read'
      },
    ],
  },
]
