import state from './moduleWageState.js'
import mutations from './moduleWageMutations.js'
import actions from './moduleWageActions.js'
import getters from './moduleWageGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
