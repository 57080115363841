export default {
  // Lead history
  getLeadHistoryPerPage: state => state.leadHistoryPerPage,
  getLeadHistoryTotalCount: state => state.leadHistoryTotalCount,
  getLeadHistoryByLeadIdPerPage: state => state.leadHistoryByLeadIdPerPage,
  getLeadHistoryByLeadIdTotalCount: state => state.leadHistoryByLeadIdTotalCount,
  // Lead history statuses
  getLeadHistoryStatus: state => state.leadHistoryStatus,
  // UTM-sources
  getUtmSourcesPerPage: state => state.utmSourcesPerPage,
  getUtmSourcesPerPageCount: state => state.utmSourcesPerPageCount,
  getUtmSourceTypes: state => state.utmSourceTypes,
  // UTM-mediums
  getUtmMediumsPerPage: state => state.utmMediumsPerPage,
  getUtmMediumsPerPageCount: state => state.utmMediumsPerPageCount,
  getUtmMediumTypes: state => state.utmMediumTypes,
}
