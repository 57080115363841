import { entityTask } from '@/libs/acl/entities'

export default [
  {
    path: '/apps/todo-my',
    name: 'apps-todo-my',
    component: () => import('@/views/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      resource: entityTask,
      action: 'read',
      title: 'Todo'
    },
  }
]
