import store from '@/store'

export const msgBoxConfirmAction = async text => await msgBoxConfirm(
  text, 'Подтверждение операции', 'Выполнить'
)

export const msgBoxConfirmCopying = async (
  text = 'Вы уверены, что хотите копировать запись?'
) => await msgBoxConfirm(text, 'Подтверждение операции копирования', 'Копировать')

export const msgBoxConfirmRemoving = async (
  text = 'Вы уверены, что хотите удалить запись?'
) => await msgBoxConfirm(text, 'Подтверждение операции удаления', 'Удалить')

const msgBoxConfirm = async (text, title, okTitle) => {
  return await store.getters['application/bvModal'].msgBoxConfirm(text, {
      okVariant: 'success',
      okTitle: okTitle,
      cancelVariant: 'danger',
      cancelTitle: 'Отмена',
      title: title,
      centered: true,
    })
}
