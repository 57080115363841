import {
  entityDentalStatusTeeth, entityPatient, entityTreatmentdiarydentalservices, entityTreatmentPresentation
} from '@/libs/acl/entities'
import { ceilNumberIsValid } from '@/composables/validator'

export default [
  {
    path: '/apps/patient_cards',
    name: 'patient-cards',
    component: () => import('@/views/patient_cards/ListPatient'),
    meta: {
      resource: entityPatient,
      action: 'read',
      title: 'Patients'
    }
  },
  {
    path: '/apps/patient_cards/patient-view/:patientId',
    name: 'app-user-view',
    component: () => import('@/views/patient_cards/PatientView.vue'),
    meta: {
      breadcrumb: [
        { title: 'Главная', url: '/' },
        { title: 'Список пациентов', url: '/apps/patient_cards' },
        { title: 'Пациент:', extendedTitle: true, active: true },
        { title: 'Просмотр', active: true }
      ],
      pageTitle: 'Просмотр карточки пациента',
      rule: 'editor',
      resource: entityPatient,
      action: 'read',
      title: 'Patient card view'
    }
  },
  {
    path: '/apps/patient_cards/patient-create',
    name: 'app-user-create',
    component: () => import('@/views/patient_cards/create/PatientCreate.vue'),
    props: true,
    meta: {
      breadcrumb: [
        { title: 'Главная', url: '/' },
        { title: 'Список пациентов', url: '/apps/patient_cards' },
        { title: 'Создать карточку', active: true }
      ],
      pageTitle: 'Создание карточки пациента',
      rule: 'editor',
      resource: entityPatient,
      action: 'create',
      title: 'Patient card creation'
    }
  },
  {
    path: '/apps/patient_cards/patient-edit/:patientId',
    name: 'app-user-edit',
    component: () => import('@/views/patient_cards/create/PatientCreate.vue'),
    meta: {
      breadcrumb: [
        { title: 'Главная', url: '/' },
        { title: 'Список пациентов', url: '/apps/patient_cards' },
        { title: 'Пациент:', extendedTitle: true, active: true },
        { title: 'Изменить карточку', active: true }
      ],
      pageTitle: 'Изменить карточку',
      rule: 'editor',
      resource: entityPatient,
      action: 'update',
      title: 'Patient card edit'
    }
  },
  {
    path: '/apps/patient_cards/patient-edit/:patientId/dental-status-add',
    name: 'app-dental-status-add',
    component: () => import('@/views/patient_cards/create/PatientDentalStatusTabForm.vue'),
    meta: {
      breadcrumb: [
        // { title: 'Главная', url: '/' },
        // { title: 'Список пациентов', url: '/apps/patient_cards' },
        // { title: 'Добавить стоматологический статус', active: true }
      ],
      pageTitle: 'Добавить стоматологический статус',
      rule: 'editor',
      resource: entityDentalStatusTeeth,
      action: 'create',
      title: 'Patient dental status creation'
    }
  },
  {
    path: '/apps/patient_cards/patient-edit/:patientId/dental-status-edit',
    name: 'app-dental-status-edit',
    component: () => import('@/views/patient_cards/create/PatientDentalStatusTabForm.vue'),
    props: true,
    meta: {
      breadcrumb: [],
      pageTitle: 'Редактировать стоматологический статус',
      rule: 'editor',
      resource: entityDentalStatusTeeth,
      action: 'create',
      title: 'Patient dental status edit'
    }
  },
  {
    path: '/apps/patient_cards/patient-edit/:patientId/dental-treatment-diary-add',
    name: 'app-treatment-diary-add',
    component: () => import('@/views/patient_cards/create/treatment_diary_dental/TreatmentDiaryForm.vue'),
    meta: {
      breadcrumb: [
        // { title: 'Главная', url: '/' },
        // { title: 'Список пациентов', url: '/apps/patient_cards' },
        // { title: 'Изменить карточку', url: '/apps/patient_cards/patient-edit/:patientId' },
        // { title: 'Добавить дневник лечения', active: true }
      ],
      pageTitle: 'Добавить дневник лечения',
      rule: 'add',
      resource: entityTreatmentdiarydentalservices,
      action: 'create',
      title: 'Patient treatment diary dental creation'
    }
  },
  {
    path: '/apps/patient_cards/patient-edit/:patientId/dental-treatment-diary-medicine-add',
    name: 'app-treatment-diary-medicine-add',
    component: () => import('@/views/patient_cards/create/treatment_diary_medicine/TreatmentDiaryMedicineForm'),
    meta: {
      breadcrumb: [
        // { title: 'Главная', url: '/' },
        // { title: 'Список пациентов', url: '/apps/patient_cards' },
        // { title: 'Изменить карточку', url: '/apps/patient_cards/patient-edit/:patientId' },
        // { title: 'Добавить дневник лечения', active: true }
      ],
      pageTitle: 'Добавить дневник лечения',
      rule: 'add',
      resource: entityTreatmentdiarydentalservices,
      action: 'create',
      title: 'Patient treatment diary medicine creation'
    }
  },
  {
    path: '/apps/patient_cards/patient-edit/:patientId/dental-treatment-diary-edit',
    name: 'app-treatment-diary-edit',
    component: () => import('@/views/patient_cards/create/treatment_diary_dental/TreatmentDiaryForm.vue'),
    props: true,
    meta: {
      breadcrumb: [],
      pageTitle: 'Редактирование дневник лечения',
      rule: 'add',
      resource: entityTreatmentdiarydentalservices,
      action: 'update',
      title: 'Patient treatment diary dental edit'
    }
  },
  {
    path: '/apps/patient_cards/patient-edit/:patientId/dental-treatment-diary-medicine-edit',
    name: 'app-treatment-diary-medicine-edit',
    component: () => import('@/views/patient_cards/create/treatment_diary_medicine/TreatmentDiaryMedicineForm.vue'),
    props: true,
    meta: {
      breadcrumb: [],
      pageTitle: 'Редактирование дневник лечения',
      rule: 'add',
      resource: entityTreatmentdiarydentalservices,
      action: 'update',
      title: 'Patient treatment diary medicine edit'
    }
  },
  {
    path: '/reception/*',
    name: 'appointment-booking',
    component: () => import('@/views/patient/AppointmentBooking.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/ab/*',
    name: 'appointment-booking-short-link',
    component: () => import('@/views/patient/AppointmentBooking.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/apps/patient_cards/patient-edit/:patientId/treatment-presentation-add',
    name: 'app-treatment-presentation-add',
    component: () => import(
      '@/views/patient_cards/create/treatment_diary_dental/treatment-presentation-tab/TreatmentPresentationForm.vue'
    ),
    meta: {
      pageTitle: 'Добавить презентацию лечения',
      resource: entityTreatmentPresentation,
      action: 'create',
      title: 'Treatment presentation creation'
    }
  },
  {
    path: '/apps/patient_cards/patient-edit/:patientId/treatment-presentation-edit/:id',
    name: 'app-treatment-presentation-edit',
    component: () => import(
      '@/views/patient_cards/create/treatment_diary_dental/treatment-presentation-tab/TreatmentPresentationForm.vue'
      ),
    meta: {
      pageTitle: 'Изменить презентацию лечения',
      resource: entityTreatmentPresentation,
      action: 'update',
      title: 'Treatment presentation edit',
      validateParam: 'id',
      validator: ceilNumberIsValid
    }
  },
]
