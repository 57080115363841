export default {
  // Purchase order
  purchaseOrders: state => state.purchaseOrders,
  purchaseOrdersTotalCount: state => state.purchaseOrdersTotalCount,
  // Implant system
  implantSystemsPerPage: state => state.implantSystemsPerPage,
  implantSystemsPerPageCount: state => state.implantSystemsPerPageCount,
  dentalLabImplantSystems: state => state.dentalLabImplantSystems,
  // Implant work type
  implantWorkTypesPerPage: state => state.implantWorkTypesPerPage,
  implantWorkTypesPerPageCount: state => state.implantWorkTypesPerPageCount,
  dentalLabImplantWorkTypes: state => state.dentalLabImplantWorkTypes,
  // Material type
  materialTypesPerPage: state => state.materialTypesPerPage,
  materialTypesPerPageCount: state => state.materialTypesPerPageCount,
  dentalLabMaterialTypes: state => state.dentalLabMaterialTypes,
  // Work type
  workTypesPerPage: state => state.workTypesPerPage,
  workTypesPerPageCount: state => state.workTypesPerPageCount,
  dentalLabWorkTypes: state => state.dentalLabWorkTypes,
  // Color palette
  colorPalettePerPage: state => state.colorPalettePerPage,
  colorPalettePerPageCount: state => state.colorPalettePerPageCount,
  dentalLabColorPalette: state => state.dentalLabColorPalette,
  // Purchase order statuses
  statuses: state => state.statuses
}
