import state from './moduleReportsState.js'
import mutations from './moduleReportsMutations.js'
import actions from './moduleReportsActions.js'
import getters from './moduleReportsGetters.js'

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
