export default {
  patientsPayments: [],
  patientsPaymentsCount: 0,
  materials_write_off: [],
  cash_register_total: null,
  total_by_line_of_work: null,
  total_by_snapshot: null,
  total_by_selling: null,
  total_by_prepayment: null,
  total_by_discount: null,
  debtors: null,
  insurances: [],
  insurancesPerPage: [],
  insurancesPerPageTotalCount: 0,
  certificates: [],
  certificatesPerPage: [],
  certificatesPerPageTotalCount: 0,
  cashlessPayments: [],
  cashRegisterSections: [
    {label: 'Итого по направлениям', section: 'line-of-work'},
    {label: 'Итого по продажам', section: 'selling'},
    {label: 'Итого по скидкам', section: 'discount'},
    {label: 'Итого по снимкам', section: 'snapshot'},
  ],
  billingStatements: [],
  billingStatementsCount: 0,
}
