export default {
  // Lead history
  leadHistoryPerPage: [],
  leadHistoryTotalCount: 0,
  leadHistoryByLeadIdPerPage: [],
  leadHistoryByLeadIdTotalCount: 0,
  leadHistoryStatus: [],
  // UTM-sources
  utmSourcesPerPage: [],
  utmSourcesPerPageCount: 0,
  utmSourceTypes: [
    {label: 'Google', type: 'google'},
    {label: 'Yandex', type: 'yandex'},
    {label: 'VK', type: 'vk'},
    {label: 'TikTok', type: 'tik-tok'},
    {label: 'Instagram', type: 'instagram'},
    {label: 'Facebook', type: 'facebook'},
    {label: 'OK', type: 'ok'},
    {label: 'Telegram', type: 'telegram'},
    {label: 'YouTube', type: 'youtube'},
    {label: 'Newsletters', type: 'trigger-newsletter'},
  ],
  // UTM-mediums
  utmMediumsPerPage: [],
  utmMediumsPerPageCount: 0,
  utmMediumTypes: [
    {label: 'Бесплатный переход', type: 'organic'},
    {label: 'Контекстная реклама', type: 'cpc'},
    {label: 'Рассылка', type: 'email'},
    {label: 'Социальные сети', type: 'social'},
    {label: 'Медийная реклама', type: 'banner'},
    {label: 'Другая реклама', type: 'cpa'}
  ],
}
