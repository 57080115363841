export default {
  // Wage by minute
  SET_WAGE_BY_MINUTE(state, items) {
    state.wageByMinute = items
  },
  // Additional wage
  SET_ADDITIONAL_WAGE(state, items) {
    state.additionalWage = items
  },
  // Service tariff history
  SET_SERVICE_TARIFF_HISTORY(state, items) {
    state.serviceTariffHistory = items
  },
  SET_SERVICE_TARIFF_HISTORY_COUNT(state, count) {
    state.serviceTariffHistoryCount = count
  },
  // Service and wage calculation
  SET_FILE_EXPORT_PROCESS_STATE: (state, active) => state.exportProcessActive = active
}
