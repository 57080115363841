import ability from './ability'
import { entityBillingStatement, entityFaq, entityImportExport } from '@/libs/acl/entities'
import appConstants from '@/constants/constants'

export const canNavigate = to => to.matched.some(route => {
  if ([entityFaq, entityBillingStatement].includes(route.meta.resource)) {
    return true
  }
  const user = JSON.parse(localStorage.getItem('user'))
  if (
    route.meta.resource === entityImportExport && user
    && [appConstants.role.type.SYS_ADMIN, appConstants.role.type.SUPPORT].includes(user.role_type)
  ) {
    return true
  }
  if (route.meta.resource === 'pricingtypevalue') {
    return user && ['sys_admin', 'root', 'support'].includes(user.role_type)
  }
  if (route.meta.resource === 'integratorbranch') {
    return user && ['root'].includes(user.role_type)
  }
  if (route.meta.resource === 'thirdpartyorganization') {
    return user && ['root'].includes(user.role_type)
  }
  return Array.isArray(route.meta.resource)
    ? route.meta.resource.some(r => ability.can(route.meta.action || 'read', r))
    : ability.can(route.meta.action || 'read', route.meta.resource)
})

export const _ = undefined
