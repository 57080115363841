export default {
  tasks: [],
  task: null,
  tags: [],
  tag: null,
  statuses: [],
  priorities: [],
  status: null,
  filterTagId: null,
  filterStatusId: null,
  filterPriorityId: null,
  dueDateCategoryFilterActive: false,
  filterDueDate: null,
  categoryTypeFilter: '',
  employeeFilter: null,
}
