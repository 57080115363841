import axios from '@/libs/axios'

export default {
  // Analytics -> Revenue
  getAnalyticsRevenueTotalByDirections({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `analytics/revenue/total-by-directions/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_ANALYTICS_REVENUE_TOTAL_BY_DIRECTIONS', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  // Analytics -> Patient appointment schedule
  getAnalyticsPatientAppointmentScheduleTotalByHowDidKnow({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `analytics/patient-appointment-schedule/total-by-how-did-know/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_ANALYTICS_PATIENT_APPOINTMENT_SCHEDULE_TOTAL_BY_HOW_DID_KNOW', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  getAnalyticsPatientAppointmentScheduleTotalByHowToContact({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `analytics/patient-appointment-schedule/total-by-how-to-contact/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_ANALYTICS_PATIENT_APPOINTMENT_SCHEDULE_TOTAL_BY_HOW_TO_CONTACT', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  getAnalyticsPatientAppointmentScheduleTotalByStatuses({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `analytics/patient-appointment-schedule/total-by-statuses/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_ANALYTICS_PATIENT_APPOINTMENT_SCHEDULE_TOTAL_BY_STATUSES', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  getAnalyticsPatientAppointmentScheduleTotalByReviews({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `analytics/patient-appointment-schedule/total-by-reviews/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_ANALYTICS_PATIENT_APPOINTMENT_SCHEDULE_TOTAL_BY_REVIEWS', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  // Analytics -> Patients
  getAnalyticsPatientsTotalByDentalServices({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `analytics/patients/total-by-dental-services/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_ANALYTICS_PATIENTS_TOTAL_BY_DENTAL_SERVICES', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  getAnalyticsPatientsTotalPatientsCount({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `analytics/patients/total-patients-count/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_ANALYTICS_PATIENTS_TOTAL_PATIENTS_COUNT', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  // Analytics -> Leads
  getAnalyticsLeadsInfo({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `analytics/leads/info/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_ANALYTICS_LEADS_INFO', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
}
