import state from './moduleRevenueState.js'
import mutations from './moduleRevenueMutations.js'
import actions from './moduleRevenueActions.js'
import getters from './moduleRevenueGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
